.rl-quick-filter {
    .open-filter,
    &.open-filter,
    .toggle-btn,
    .sort-btn {
        line-height: 36px;
        padding: 0 var(--m-spacing);
        font-size: 0.875rem;
        border-radius: var(--general-border-radius);
        background-color: rgba(var(--general-text-color-rgb), 0.06);
        cursor: pointer;
        backdrop-filter: blur(12px);

        &:hover {
            background-color: rgba(var(--general-text-color-rgb), 0.1);
        }

        rl-icon {
            font-size: 0.7rem;
            color: var(--general-text-inactive-color);
        }

        &.active,
        &.selected {
            background-color: var(--highlight-color);
            color: var(--text-on-highlight-color);
            border: 1px solid var(--highlight-color);
            font-weight: 600;
            max-height: 36px;
        
            &:hover {
                background-color: color-mix(in srgb, var(--highlight-color) 90%, var(--text-on-highlight-color) 10%);
            }
        
            rl-icon {
                color: var(--text-on-highlight-color);
            }    
        }
    }
}