rl-rich-textarea {
    display: block;
    position: relative;
    background-color: var(--general-background-color);
    min-height: 251px;

    border-top: 1px solid var(--general-border-color);
    border-bottom: 1px solid var(--general-border-color);

    button[data-cmd="viewImage"],
    button[data-cmd="editImage"] {
        display: none;
    }

    .rich-toolbar {
        position: sticky;
        top: 0;
        z-index: 10;
        width: 100%;
    }

    .fr-box.fr-basic {
        height: 100%;
        display: flex;
        flex-direction: column;

        .fr-wrapper {
            flex: 1;
            border: none;
            background-color: var(--general-background-color);
            border-radius: 0;

            .fr-placeholder {
                color: rgba(var(--general-text-color-rgb), 0.4);
            }
        }

        .fr-element {
            min-height: 250px;
            padding: 10px;

            line-height: 1.3;
            color: var(--general-text-color);

            h1 {
                margin: 0.8rem 0 0.3rem 0;
                line-height: 1.1;
                font-size: 2rem;
            }

            h2 {
                margin: 0.8rem 0 0.3rem 0;
                line-height: 1.1;
                font-size: 1.5rem;
            }

            h3 {
                margin: 0.8rem 0 0.3rem 0;
                line-height: 1.1;
                font-size: 1.3rem;
            }

            h4 {
                margin: 0.8rem 0 0.3rem 0;
                line-height: 1.1;
                font-size: 1rem;
            }

            p {
                font-size: 15px;
                padding: 0;
                margin: 0 0 10px 0;
            }

            ul, ol {
                margin-block-start: 1rem;
                margin-block-end: 1rem;
            }

            table td, table th {
                border-color: var(--general-border-color);

                &.fr-selected-cell {
                    border-color: var(--highlight-color);
                }
            }
        }

        .fr-dropdown-wrapper {
            max-height: 200px !important;
        }

        .fr-layer {
            margin: 10px 20px;
        }
    }

    .fr-view .fr-video {
        margin: 0 5px;

        &.fr-dvi.fr-fvl {
            margin-right: 15px;
        }
    }

    .fr-placeholder {
        color: rgba(var(--general-background-color-rgb));
    }

    .fr-second-toolbar {
        display: none;
    }

    .fr-selected-color {
        font-family: 'Font Awesome 5 Pro' !important;
    }

    .field-postfix {
        display: none; // rl-new-form adds locked icon
    }
}

.fr-popup {
    z-index: 202 !important;
    background: var(--general-background-color);
    color: var(--general-text-color);

    .fr-buttons {
        &.fr-tabs {
            background: var(--general-background-color);
        }

        .fr-btn {
            color: var(--general-text-color);

            &.fr-dropdown:after {
                border-top-color: var(--general-text-color);
                border-bottom-color: var(--general-text-color);
            }

            svg path {
                fill: var(--general-text-color);
            }
        }
    }

    .fr-selected-set {
        padding: 15px !important;

        > span {
            width: 35px;
            height: 35px;
        }
    }

    .fr-link-insert-layer {

        .fr-input-line {
            padding: 0;
            margin-bottom: 15px;

            input {
                &.fr-not-empty + label {
                    top: -7px;
                }
            }

            label {
                top: 8px;
            }
        }

        .fr-checkbox-line {
            float: left
        }

        .fr-checkbox {
            padding: 0;
        }
    }

    .fr-video-by-url-layer {
        margin-top: 0;
    }

    .fr-video-embed-layer {
        .fr-input-line {
            padding: 0;
        }
    }

    .fr-table-colors {
        padding: 15px !important;
    }

    .fr-table-size {
        .fr-select-table-size > span > span {
            border-color: var(--general-border-color);
        }
    }

    .fr-dropdown-menu {
        background-color: var(--general-background-color) !important;
    }
}

.fr-toolbar {
    background: var(--general-background-color);
    color: var(--general-text-color);
    border: 0;
    border-radius: 0 !important;

    .fr-more-toolbar {
        background: var(--background-highlight-color) !important;
    }

    .fr-newline {
        margin: 0;
        background: var(--general-border-color) !important;
    }

    .fr-dropdown-menu {
        background-color: var(--general-background-color) !important;
    }

    @media @mobile-max {
        .fr-btn-grp {
            margin: 0;
        }
    }

    .fr-command.fr-btn {
        color: var(--general-text-color);
        fill: var(--general-text-color);
        border-left: none !important;

        &.fr-dropdown:after, &.fr-dropdown.fr-active:after {
            border-top-color: var(--general-text-color);
            border-bottom-color: var(--general-text-color);
        }

        &.fr-active {
            background: var(--background-highlight-color) !important;

            svg path {
                fill: var(--highlight-color) !important;
            }
        }

        &.fr-open {
            background: var(--background-highlight-color) !important;

        }

        svg path {
            fill: var(--general-text-color);
        }
    }
}

.fr-separator {
    background: var(--general-border-color);
}