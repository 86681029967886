.search-result-placeholder {
    display: flex;
    gap: var(--m-spacing);
    margin-bottom: var(--spacing);

    .image {
        flex-shrink: 0;
        width: 50px;
        height: 50px;
        border-radius: var(--general-border-radius);

        @media @desktop-up {
            width: 40px;
            height: 40px;
        }
    }

    .text {
        flex-basis: 100%;

        .placeholder-shimmer {
            display: block;
            height: 10px;
            width: 100%;
            margin-bottom: 6px;
            border-radius: var(--general-border-radius);
        }

        .title .placeholder-shimmer {
            height: 22px;
        }

        .meta .placeholder-shimmer {
            margin-top: var(--m-spacing);
        }
    }

    &.social {
        .title {
            display: none;
        }
    }

    &.catalog {
        .meta {
            display: none;
        }
    }
}