rl-rich-preview {
    display: block;

    .placeholder-shimmer {
        height: 19px;
    }

    .content {
        width: 100%;
        transition: max-height 0.2s ease-out;

        &.loading {
            visibility: hidden;
            position: absolute;
            overflow: hidden;
            max-height: 90px;
            top: 0;
        }

        &.read-more-enabled {
            position: relative;
            overflow: hidden;

            @media @tablet-max {
                max-height: 90px;
            }
            @media @tablet-up {
                max-height: 180px;
            }

            &.closed {
                &:after {
                    content: '';
                    z-index: 1;
                    display: block;
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: -10px;

                    height: 30px;
                    background: linear-gradient(180deg, rgba(var(--general-background-color-rgb), 0) 0%, var(--general-background-color) 80%);
                }
            }
        }

        .text {
            h1 {
                margin: 0.8rem 0 0.3rem 0;
                line-height: 1.1;
                font-size: 25px;
                font-weight: 700;
            }

            h2 {
                margin: 0.8rem 0 0.3rem 0;
                line-height: 1.1;
                font-size: 23px;
                font-weight: 700;
            }

            h3 {
                margin: 0.8rem 0 0.3rem 0;
                line-height: 1.1;
                font-size: 21px;
                font-weight: 700;    
            }

            h4 {
                margin: 0.8rem 0 0.3rem 0;
                line-height: 1.1;
                font-size: 19px;
                font-weight: 700;     
            }
            
            h5 {
                margin: 0.8rem 0 0.3rem 0;
                font-size: 17px;
                font-weight: 700;
            }
            
            h6 {
                margin: 0.8rem 0 0.3rem 0;
                font-size: 15px;
                font-weight: 700;
            }

            pre {
                margin: 0.8rem 0 0.3rem 0;
                color: var(--general-text-inactive-color);
                font-weight: 400;
                font-size: 15px;
                padding: 15px;
                border: 1px solid var(--general-border-color);
                border-radius: 7px;
                white-space: pre-line;
                background-color: var(--background-highlight-color);
                display: inline-block;
                max-width: 100%;
            }

            p {
                font-size: 15px;
                font-weight: 400;
                padding: 0;
                margin: 0 0 10px 0;

                &.p-top {
                    margin-top: 10px;
                }

                &:last-of-type {
                    margin: 0;
                }
            }
            
            hash-tag {
                color: var(--highlight-color);
                font-size: 15px;
                font-weight: 700;
            }
            
            a, user-mention, department-mention, user-group-mention {
                padding: 5px 8px;
                color: var(--general-text-color);
                background-color: var(--background-highlight-color);
                border-radius: 4px;
                overflow: hidden;
                white-space: nowrap;
                border: 1px solid var(--general-border-color);
                text-overflow: ellipsis;
                display: inline-block;
                max-width: 100%;
                font-weight: 400 !important;
                vertical-align: middle;
            }

            user-mention, department-mention, user-group-mention {
                color: var(--general-text-inactive-color) !important;
            }
            
            a {
                &.btn {
                    background-color: var(--button-background-color);
                    color: var(--button-text-color);
                    display: flex;
                    align-items: center;
                    text-decoration: none;
                    justify-content: center;
                    
                    &:before {
                        content: none;
                    }
                }
                
                &:before {
                    font-family: "Font Awesome 5 Pro";
                    font-weight: 400;
                    content: "\f0c1";
                    display: block;
                    width: 16px;
                    height: 16px;
                    float: left;
                    margin: 0 6px 0 0;
                }
            }

            ul, ol {
                padding-inline-start: 20px;
                margin-block-start: 1rem;
                margin-block-end: 1rem;
                
                li:not(:last-of-type) {
                    margin-bottom: 15px;
                }
            }
            
            ol {
                padding-inline-start: 40px;
            }
            
            ul {
                li::marker {
                    color: var(--general-text-inactive-color);
                }
            }

            &:not(:has(> br:last-child)) {
                padding-bottom: 20px;
            }

            video {
                background: grey;
            }

            hash-tag {
                color: var(--highlight-color);
                font-weight: bold;
            }

            user-mention, department-mention, hash-tag {
                cursor: pointer;
            }

            .table-wrapper {
                overflow-x: auto;

                table {
                    td, th {
                        border: 1px solid var(--general-border-color);
                    }
                }
            }

            media {
                position: relative;
                max-width: 100%;

                &.fr-dib {
                    margin: 5px auto;
                    display: block;
                    float: none;
                    vertical-align: top;

                    &:not(.fr-fil):not(.fr-fir) {
                        text-align: center;
                    }

                    > .media {
                        display: inline-block;
                    }
                }

                &.fr-dib.fr-fil {
                    margin-left: 0;
                    text-align: left;
                }

                &.fr-dib.fr-fir {
                    margin-right: 0;
                    text-align: right;
                }

                &.fr-dii {
                    display: inline-block;
                    float: none;
                    vertical-align: bottom;
                    margin-left: 5px;
                    margin-right: 5px;
                    max-width: calc(100% - (2 * 5px));
                }

                &.fr-dii.fr-fil {
                    float: left;
                    margin: 5px 15px 5px 0;
                    max-width: calc(100% - 5px);
                }

                &.fr-dii.fr-fir {
                    float: right;
                    margin: 5px 0 5px 5px;
                    max-width: calc(100% - 5px);
                }

                &.fr-video.fr-dvi {
                    margin: 0 5px;
                }

                &.fr-video.fr-dvi.fr-fvl {
                    margin-right: 15px;
                }

                &.fr-video[resize-width] > * {
                    display: inline-block;
                }

                img {
                    display: inline-block;
                }
            }
        }
    }

    .read-more {
        width: 100%;
        margin: 10px 0;
        height: 30px;
        line-height: 30px;

        color: var(--general-text-color);
        background: none;
        border: none;

        text-align: center;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 12px;
        opacity: 0.5;
    }
}