rl-toggle-filters {
    position: relative;
    display: inline-block;
}

rl-toggle-filters-popup {
    action-sheet-custom .actionsheet {

        @media @desktop-up {
            margin-top: 50px;
            padding: 10px;
            width: fit-content;
        }

        .actionsheet-content {
            padding-top: 0;
        }
    }

    @media @desktop-up {
        rl-select-list {
            flex-direction: row;
            flex-wrap: wrap;
            margin-bottom: 10px;

            .item {
                flex-direction: row-reverse;
                justify-content: flex-end;

                .title {
                    white-space: nowrap;
                }
                
                .icon {
                    display: none;
                }
            }
        }
    }
}