rl-sorting {
    display: block;
    position: relative;

    > button {
        padding: 0;
    }

    &.hidden {
        display: none;
    }
}

rl-sorting-popup {
    action-sheet-custom .actionsheet {

        @media @desktop-up {
            width: 160px;
            margin-top: 27px;
            padding: 10px;
            right: 0;
            left: auto !important;

            .icon {
                display: none;
            }

            rl-select-list {
                .item {
                    flex-direction: row-reverse;
                    justify-content: flex-end;
                }
            }
        }

        .actionsheet-content {
            padding-top: 0;
        }
    }
}