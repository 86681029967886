/* components */
header-popup {
  display: flex;
  width: 100%;
  height: 55px;
  min-height: 55px;
  justify-content: space-between;
  align-items: center;
  background: var(--header-background-color);
  color: var(--header-text-color);
}
header-popup left-buttons,
header-popup right-buttons {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}
header-popup .buttons {
  min-width: 45px;
  height: 100%;
}
header-popup .buttons button {
  width: 45px;
  min-width: 45px;
  height: 100%;
  padding: 0;
  border: none;
  background: transparent;
  color: var(--header-text-color);
}
header-popup .buttons button[disabled],
header-popup .buttons button.inactive {
  opacity: 0.5;
}
header-popup .title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  flex-direction: column;
  height: 100%;
  padding: 0 15px;
  box-sizing: border-box;
  overflow: hidden;
}
header-popup .title-slot {
  flex: 1;
  padding: 0 15px;
  box-sizing: border-box;
  overflow: hidden;
}
header-popup .title h1,
header-popup .title-slot h1,
header-popup .title h2,
header-popup .title-slot h2 {
  overflow: hidden;
  margin: 0;
  width: 100%;
  font-size: 17px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
header-popup .title h2,
header-popup .title-slot h2 {
  font-size: 13px;
  text-transform: none;
}
.rl-message .message-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.rl-message .alert-icon {
  align-items: flex-start;
  min-width: 2rem;
}
.rl-message .alert-icon rl-icon {
  color: var(--alert-color);
}
.rl-message .warning-icon {
  align-items: center;
  min-width: 4rem;
  border-right: 1px solid #97443E;
}
.rl-message .info-icon {
  align-items: flex-start;
  min-width: 2rem;
}
.rl-message .info-icon rl-icon {
  color: var(--info-color);
}
.rl-message .message-text {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.rl-message .alert-text,
.rl-message .info-text {
  color: var(--general-text-color);
}
.rl-message .warning-text {
  padding: 0.5rem 1rem;
}
.rl-message .title {
  display: flex;
}
.rl-message .info-title {
  color: var(--general-text-color);
  display: flex;
  padding-bottom: 10px;
}
.rl-message .close {
  color: var(--general-text-color) !important;
  opacity: 0.5;
  position: absolute;
  right: 15px;
  top: 65px;
}
.rl-alert {
  display: flex;
  color: var(--button-text-color);
  font-weight: 400;
  font-size: 1rem;
  background: rgba(var(--alert-color-rgb), 0.2);
  border: none;
  padding: 1rem;
}
.rl-warning {
  display: flex;
  border-radius: var(--general-border-radius);
  color: var(--button-text-color);
  font-weight: 500;
  font-size: 1rem;
  background: var(--warning-color);
  border: 1px solid #97443E;
}
.rl-info {
  color: var(--button-text-color);
  font-weight: 400;
  font-size: 1rem;
  background: rgba(var(--info-color-rgb), 0.2);
  border-radius: var(--general-border-radius);
  padding: 1rem;
}
approve-slider {
  display: block;
  position: relative;
  max-width: 345px;
  width: 100%;
}
approve-slider .line {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 34px;
  border-radius: 20px;
  background-color: rgba(var(--general-text-color-rgb), 0.3);
  transition: background-color 0.3s ease-in;
}
approve-slider .line.approved {
  background-color: var(--confirmation-color);
}
approve-slider .line.approved .swiper {
  color: var(--confirmation-color);
  box-shadow: 0 5px 7px rgba(var(--confirmation-color-rgb), .1);
}
approve-slider .line.approved .approved-text {
  right: 50px;
}
approve-slider .line.rejected {
  background-color: var(--warning-color);
}
approve-slider .line.rejected .swiper {
  color: var(--warning-color);
  box-shadow: 0 5px 7px rgba(var(--warning-color-rgb), .1);
}
approve-slider .line.rejected .rejected-text {
  left: 50px;
}
approve-slider .swiper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: var(--white-color);
  box-shadow: 0 5px 7px rgba(var(--general-text-color-rgb), .1);
  transform: translateX(0);
}
approve-slider .swiper rl-icon {
  font-size: 28px;
}
approve-slider .rejected-text,
approve-slider .approved-text {
  position: absolute;
  color: var(--white-color);
  font-size: 14px;
  text-transform: uppercase;
  transition-duration: 0.1s;
}
approve-slider .rejected-text {
  left: 15px;
}
approve-slider .approved-text {
  right: 15px;
}
rl-toggle input[type=checkbox] {
  opacity: 0;
  position: absolute;
  left: -9999px;
}
rl-toggle input[type=checkbox] + label {
  display: inline-block;
  position: relative;
  margin: 0;
  padding: 0;
  height: 26px;
  width: 50px;
  border-radius: 13px;
  background: rgba(var(--general-text-color-rgb), 0.2);
  transition: background 0.2s ease-in-out, opacity 0.2s ease-in-out;
  cursor: pointer;
  user-select: none;
}
rl-toggle input[type=checkbox] + label:after {
  height: 22px;
  width: 22px;
  border-radius: 50%;
  display: block;
  background: #fff;
  position: absolute;
  z-index: 1;
  left: 2px;
  top: 2px;
  content: '';
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
}
rl-toggle input[type=checkbox] + label:hover {
  background: rgba(var(--general-text-color-rgb), 0.3);
}
rl-toggle input[type=checkbox]:checked + label {
  background: var(--highlight-color);
  opacity: 1;
}
rl-toggle input[type=checkbox]:checked + label:after {
  transform: translate3d(24px, 0, 0);
}
rl-toggle input[type=checkbox]:checked + label:hover {
  background: rgba(var(--highlight-color-rgb), 0.8);
}
rl-toggle input[type=checkbox]:disabled + label {
  background: var(--general-text-inactive-color);
}
rl-progress {
  display: flex;
  flex-direction: column;
}
rl-progress .header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
}
rl-progress .bar {
  position: relative;
  width: 100%;
  height: 10px;
  background: rgba(var(--general-text-color-rgb), 0.2);
  backdrop-filter: blur(12px);
  border-radius: 20px;
}
rl-progress .bar .inner {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  border-radius: 20px;
  background: var(--highlight-color);
}
confirm-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}
confirm-popup .bg {
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}
confirm-popup .bg.animated {
  animation-duration: 0.4s;
}
confirm-popup .confirmation-popup {
  z-index: 2;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100px;
  width: 80%;
  max-width: 325px;
  text-align: center;
  background: var(--general-background-color);
  border-radius: var(--general-border-radius);
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
}
@media only screen and (min-width: 1025px) {
  confirm-popup .confirmation-popup {
    max-width: 500px;
    margin-left: var(--sidemenu-width);
  }
}
confirm-popup .confirmation-popup.animated {
  animation-duration: 0.4s;
}
confirm-popup .confirmation-popup .content {
  padding: 25px 15px 20px 15px;
}
confirm-popup .confirmation-popup h1 {
  margin: 0;
  padding-bottom: 10px;
  color: var(--general-text-color);
}
confirm-popup .confirmation-popup p {
  margin: 0;
  max-height: 300px;
  overflow-y: auto;
  line-height: 1.4;
  overscroll-behavior: none;
  -webkit-overflow-scrolling: touch;
  color: var(--general-text-color);
}
confirm-popup .confirmation-popup footer {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--general-border-color);
}
confirm-popup .confirmation-popup footer rl-button {
  width: 50%;
}
confirm-popup .confirmation-popup footer rl-button.no button {
  color: var(--general-text-color);
  opacity: 0.4;
}
confirm-popup .confirmation-popup.alert footer {
  justify-content: center;
}
prompt-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}
prompt-popup .bg {
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}
prompt-popup .bg.animated {
  animation-duration: 0.4s;
}
prompt-popup .prompt-popup {
  z-index: 2;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100px;
  width: 80%;
  max-width: 325px;
  text-align: center;
  background: var(--white-color);
  border-radius: var(--general-border-radius);
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
}
@media only screen and (min-width: 1025px) {
  prompt-popup .prompt-popup {
    transform: translateX(calc(var(--sidemenu-width) / 2));
  }
}
prompt-popup .prompt-popup.animated {
  animation-duration: 0.4s;
}
prompt-popup .prompt-popup .content {
  padding: 30px 15px 0 15px;
  position: relative;
}
prompt-popup .prompt-popup rl-icon {
  position: absolute;
  width: 43px;
  height: 43px;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  font-size: 26px;
  background: var(--highlight-color);
  color: var(--text-on-highlight-color);
}
prompt-popup .prompt-popup h1 {
  margin: 0;
  font-size: 15px;
  padding-bottom: 24px;
}
prompt-popup .prompt-popup textarea {
  width: 100%;
  height: 115px;
  box-sizing: border-box;
  padding: 10px;
  border: none;
  -webkit-appearance: none;
  background: var(--background-highlight-color);
}
prompt-popup .prompt-popup textarea::placeholder {
  color: rgba(var(--general-text-color-rgb), 0.4);
}
prompt-popup .prompt-popup rl-button {
  display: block;
  padding: 15px;
}
action-sheet {
  z-index: 900;
  transition-duration: 0.15s;
  transition-property: opacity;
  transition-timing-function: ease-in-out;
  overscroll-behavior: none;
}
action-sheet .actionsheet-group {
  max-height: var(--max-mobile-sheet-height);
  overflow: auto;
}
@media only screen and (min-width: 1025px) {
  action-sheet .actionsheet-group {
    max-height: var(--max-desktop-sheet-height);
  }
}
action-sheet .reaction {
  background: none !important;
  box-shadow: none !important;
}
action-sheet reaction-icon,
action-sheet reaction-icon img {
  width: 100% !important;
  height: 100% !important;
}
action-sheet reaction-icon rl-icon span {
  font-size: 14px !important;
}
action-sheet:not(.inline) {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
action-sheet:not(.inline):not(.attached) {
  background: rgba(0, 0, 0, 0.4);
}
action-sheet.ng-enter,
action-sheet.ng-leave-active {
  opacity: 0;
}
action-sheet.ng-enter .actionsheet,
action-sheet.ng-leave-active .actionsheet {
  transform: translate3d(0, 100%, 0);
}
action-sheet.ng-enter-active {
  opacity: 1;
}
action-sheet .actionsheet-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
action-sheet.attached .actionsheet {
  top: 10px;
  right: 10px;
  bottom: auto;
  left: auto;
  border-radius: var(--general-border-radius);
  border: 1px solid var(--general-background-color);
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
action-sheet.attached.ng-enter .actionsheet,
action-sheet.attached.ng-leave-active .actionsheet {
  transform: translate3d(0, 0, 0);
}
action-sheet.attached .actionsheet-group .actionsheet-description,
action-sheet.inline .actionsheet-group .actionsheet-description {
  font-size: 11px;
  font-weight: 400;
  color: var(--general-text-color);
  padding: 10px;
  padding-bottom: 5px;
  text-transform: uppercase;
  opacity: 0.6;
}
action-sheet.attached .actionsheet-group .actionsheet-button,
action-sheet.inline .actionsheet-group .actionsheet-button,
action-sheet.attached .actionsheet-group .actionsheet-label,
action-sheet.inline .actionsheet-group .actionsheet-label {
  padding: 0 10px;
}
action-sheet:not(.attached):not(.inline) .actionsheet {
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding-top: 25px;
  padding-bottom: env(safe-area-inset-bottom, 0px);
  border-radius: 15px 15px 0 0;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}
action-sheet:not(.attached):not(.inline) .actionsheet .divider {
  position: relative;
  display: block;
  top: -15px;
  width: 42px;
  height: 5px;
  margin: 0 auto;
  border-radius: 7px;
  background: var(--text-on-white-color);
  opacity: 0.7;
}
action-sheet:not(.attached):not(.inline) .actionsheet .actionsheet-group .actionsheet-description {
  display: none;
}
action-sheet:not(.attached):not(.inline) .actionsheet .actionsheet-group .actionsheet-button,
action-sheet:not(.attached):not(.inline) .actionsheet .actionsheet-group .actionsheet-label {
  padding: 0 15px;
}
action-sheet:not(.attached):not(.inline) .actionsheet .actionsheet-header {
  text-align: center;
  padding: 10px;
  border-bottom: 1px solid #ebecee;
  white-space: nowrap;
}
action-sheet:not(.attached):not(.inline) .actionsheet .actionsheet-header .actionsheet-image {
  display: inline-block;
  position: relative;
  margin-bottom: 4px;
}
action-sheet:not(.attached):not(.inline) .actionsheet .actionsheet-header .actionsheet-description {
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 75vw;
  margin: 0 auto;
}
action-sheet:not(.attached):not(.inline) .actionsheet .actionsheet-header img {
  width: 57px;
  height: 57px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 5px;
}
action-sheet:not(.attached):not(.inline) .actionsheet .actionsheet-header .head-icon {
  width: 57px;
  color: #3c4858;
}
action-sheet:not(.attached):not(.inline) .actionsheet .actionsheet-header .head-icon svg {
  padding: 1px;
  box-sizing: border-box;
  border-radius: 50%;
}
action-sheet:not(.attached):not(.inline) .actionsheet .actionsheet-header .head-icon svg path {
  fill: #8893a3;
}
action-sheet:not(.attached):not(.inline) .actionsheet .actionsheet-header .badge-icon {
  position: absolute;
  line-height: 14px;
  right: -3px;
  bottom: -1px;
  top: auto;
  background: #3c4858;
  color: white;
  border-radius: 50%;
  width: 27px;
  height: 27px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
}
action-sheet:not(.attached):not(.inline) .actionsheet .actionsheet-header .badge-icon rl-icon {
  font-size: 14px;
}
action-sheet:not(.attached):not(.inline) .actionsheet .actionsheet-header .badge-icon.socialPost {
  background: #eb6262;
}
action-sheet:not(.attached):not(.inline) .actionsheet .actionsheet-header .badge-icon.contentItem {
  background: #5fa3f1;
}
action-sheet:not(.attached):not(.inline) .actionsheet .actionsheet-header .badge-icon.serviceFormFilling {
  background: #3c4858;
}
action-sheet:not(.attached):not(.inline) .actionsheet .actionsheet-header .badge-icon icon {
  width: 14px;
  height: 14px;
  color: #fff;
}
action-sheet:not(.attached):not(.inline) .actionsheet .actionsheet-header .badge-icon icon svg {
  stroke-width: 0;
  border-radius: 0;
  padding: 0;
}
action-sheet:not(.attached):not(.inline) .actionsheet .actionsheet-header .badge-icon icon svg path {
  fill: #fff;
}
action-sheet .actionsheet {
  display: block;
  position: absolute;
  z-index: 2;
  background: var(--white-color);
  transform: translate3d(0, 0, 0);
}
action-sheet .actionsheet .actionsheet-group {
  border-bottom: 1px solid #ddd;
}
action-sheet .actionsheet .actionsheet-group:last-child {
  border: none;
}
action-sheet .actionsheet .actionsheet-group .actionsheet-button,
action-sheet .actionsheet .actionsheet-group .actionsheet-label {
  display: flex;
  width: 100%;
  height: 40px;
  margin: 0;
  align-items: center;
  overflow: hidden;
  position: relative;
  text-align: left;
  color: unset;
  border: none;
  background: none;
  box-sizing: border-box;
  text-decoration: none;
}
action-sheet .actionsheet .actionsheet-group .actionsheet-button rl-icon,
action-sheet .actionsheet .actionsheet-group .actionsheet-label rl-icon {
  margin-right: 10px;
  margin-top: -3px;
  color: var(--text-on-white-color);
}
action-sheet .actionsheet .actionsheet-group .actionsheet-button rl-icon.right-icon,
action-sheet .actionsheet .actionsheet-group .actionsheet-label rl-icon.right-icon {
  margin-right: 0;
  padding-left: 10px;
  margin-left: auto;
}
action-sheet .actionsheet .actionsheet-group .actionsheet-button rl-icon.active,
action-sheet .actionsheet .actionsheet-group .actionsheet-label rl-icon.active {
  color: var(--highlight-color);
}
action-sheet .actionsheet .actionsheet-group .actionsheet-button rl-icon.active.label-color + .text-wrapper span,
action-sheet .actionsheet .actionsheet-group .actionsheet-label rl-icon.active.label-color + .text-wrapper span {
  color: var(--highlight-color);
}
action-sheet .actionsheet .actionsheet-group .actionsheet-button rl-icon.red,
action-sheet .actionsheet .actionsheet-group .actionsheet-label rl-icon.red {
  color: var(--warning-color);
}
action-sheet .actionsheet .actionsheet-group .actionsheet-button rl-icon.red.label-color + .text-wrapper span,
action-sheet .actionsheet .actionsheet-group .actionsheet-label rl-icon.red.label-color + .text-wrapper span {
  color: var(--warning-color);
}
action-sheet .actionsheet .actionsheet-group .actionsheet-button rl-icon.blue,
action-sheet .actionsheet .actionsheet-group .actionsheet-label rl-icon.blue {
  color: var(--highlight-color);
}
action-sheet .actionsheet .actionsheet-group .actionsheet-button rl-icon.blue.label-color + .text-wrapper span,
action-sheet .actionsheet .actionsheet-group .actionsheet-label rl-icon.blue.label-color + .text-wrapper span {
  color: var(--highlight-color);
}
action-sheet .actionsheet .actionsheet-group .actionsheet-button rl-icon.green,
action-sheet .actionsheet .actionsheet-group .actionsheet-label rl-icon.green {
  color: var(--confirmation-color);
}
action-sheet .actionsheet .actionsheet-group .actionsheet-button rl-icon.green.label-color + .text-wrapper span,
action-sheet .actionsheet .actionsheet-group .actionsheet-label rl-icon.green.label-color + .text-wrapper span {
  color: var(--confirmation-color);
}
action-sheet .actionsheet .actionsheet-group .actionsheet-button rl-icon.orange,
action-sheet .actionsheet .actionsheet-group .actionsheet-label rl-icon.orange {
  color: var(--quiz-color);
}
action-sheet .actionsheet .actionsheet-group .actionsheet-button rl-icon.orange.label-color + .text-wrapper span,
action-sheet .actionsheet .actionsheet-group .actionsheet-label rl-icon.orange.label-color + .text-wrapper span {
  color: var(--quiz-color);
}
action-sheet .actionsheet .actionsheet-group .actionsheet-button rl-icon.purple,
action-sheet .actionsheet .actionsheet-group .actionsheet-label rl-icon.purple {
  color: var(--service-form-color);
}
action-sheet .actionsheet .actionsheet-group .actionsheet-button rl-icon.purple.label-color + .text-wrapper span,
action-sheet .actionsheet .actionsheet-group .actionsheet-label rl-icon.purple.label-color + .text-wrapper span {
  color: var(--service-form-color);
}
action-sheet .actionsheet .actionsheet-group .actionsheet-button rl-icon.red-dot::before,
action-sheet .actionsheet .actionsheet-group .actionsheet-label rl-icon.red-dot::before {
  content: '';
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: var(--warning-color);
  top: 8px;
  left: 30px;
  border: 1px solid white;
}
action-sheet .actionsheet .actionsheet-group .actionsheet-button icon,
action-sheet .actionsheet .actionsheet-group .actionsheet-label icon {
  margin-right: 10px;
  color: unset;
}
action-sheet .actionsheet .actionsheet-group .actionsheet-button icon.red.label-color + .text-wrapper span,
action-sheet .actionsheet .actionsheet-group .actionsheet-label icon.red.label-color + .text-wrapper span {
  color: var(--warning-color);
}
action-sheet .actionsheet .actionsheet-group .actionsheet-button icon.red-dot,
action-sheet .actionsheet .actionsheet-group .actionsheet-label icon.red-dot {
  position: relative;
  display: inline-block;
}
action-sheet .actionsheet .actionsheet-group .actionsheet-button icon svg,
action-sheet .actionsheet .actionsheet-group .actionsheet-label icon svg {
  stroke-width: 0;
  max-width: 18px;
}
action-sheet .actionsheet .actionsheet-group .actionsheet-button icon.icon-shape svg,
action-sheet .actionsheet .actionsheet-group .actionsheet-label icon.icon-shape svg {
  width: 20px;
  max-width: 20px;
}
action-sheet .actionsheet .actionsheet-group .actionsheet-button icon.icon-shape.big svg,
action-sheet .actionsheet .actionsheet-group .actionsheet-label icon.icon-shape.big svg {
  width: 26px;
  max-width: 26px;
}
action-sheet .actionsheet .actionsheet-group .actionsheet-button .text-wrapper,
action-sheet .actionsheet .actionsheet-group .actionsheet-label .text-wrapper {
  display: flex;
  flex-direction: column;
  color: var(--text-on-white-color);
}
action-sheet .actionsheet .actionsheet-group .actionsheet-button .text,
action-sheet .actionsheet .actionsheet-group .actionsheet-label .text {
  display: block;
}
action-sheet .actionsheet .actionsheet-group .actionsheet-label {
  display: flex;
  flex-direction: column;
  padding-top: 14px;
  padding-bottom: 6px;
  min-height: 1px;
  color: #aaa;
  line-height: 16px;
}
action-sheet .actionsheet .actionsheet-group .actionsheet-button.actionsheet-button-disabled {
  opacity: 0.5;
}
.action-disable-scroll {
  overflow: hidden;
}
tip .tip-content {
  z-index: 50;
  display: block;
  position: relative;
  padding: 10px 20px;
  margin-bottom: 25px;
  width: 220px;
  border-radius: 4px;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.6);
  color: var(--text-on-white-color);
  text-align: center;
  box-sizing: border-box;
  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.1);
  transform: translateY(0);
}
tip .tip-content.is-hidden {
  position: absolute;
}
tip .tip-content h3 {
  margin: 0;
  font-size: 13px;
  line-height: 16px;
  font-weight: 600;
}
tip .tip-content p {
  margin: 0;
  font-size: 13px;
  line-height: 16px;
}
tip .tip-content.hide {
  opacity: 0;
}
tip .tip-content:after {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -4px;
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 7.5px 0 7.5px;
  border-color: rgba(255, 255, 255, 0.6) transparent transparent transparent;
}
tip.top .tip-content:after {
  top: -4px;
  border-width: 0 7.5px 4px 7.5px;
  border-color: transparent transparent rgba(255, 255, 255, 0.6) transparent;
}
tip .overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 40;
}
rl-search {
  display: block;
  position: relative;
  height: 45px;
}
rl-search rl-icon[icon=search] {
  position: absolute;
  top: 0;
  padding: 13px 10px;
}
rl-search rl-icon[icon=search] {
  left: 0;
}
rl-search rl-icon[icon=close] {
  position: absolute;
  top: 0;
  right: 0;
  width: 19px;
  height: 19px;
  margin: 13px 10px;
  font-size: 14px;
  border-radius: 50%;
  color: var(--general-text-color-rgb, 0.7);
  background: var(--white-color);
}
rl-search input {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0 40px;
  border: none;
  border-radius: 7px;
  background: rgba(var(--general-text-color-rgb), 0.07);
  font-size: 13px;
  color: var(--general-text-color);
  -webkit-appearance: none;
}
rl-search input:placeholder-shown {
  text-overflow: ellipsis;
}
rl-search input::placeholder {
  color: rgba(var(--general-text-color-rgb), 0.4);
}
rl-badge {
  position: relative;
  display: block;
  width: 20px;
  min-width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  font-size: 10px;
  border-radius: 100%;
  overflow-y: hidden;
}
rl-badge.small {
  width: 16px;
  height: 16px;
  line-height: 16px;
  font-size: 9px;
}
rl-badge .background {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: var(--badge-background-color);
}
rl-badge .number {
  position: relative;
  z-index: 1;
  color: var(--badge-text-color);
}
rl-textarea {
  display: flex;
  width: 100%;
  position: relative;
}
rl-textarea textarea {
  width: 100%;
  min-height: 120px;
  padding: 10px;
  box-sizing: border-box;
  font-size: 15px;
  line-height: 1.3;
  background: var(--background-highlight-color);
  color: var(--general-text-color);
  -webkit-appearance: none;
  resize: none;
  border: 1px solid transparent;
  border-radius: 7px;
}
rl-textarea textarea.validate.ng-valid.ng-dirty {
  border: 1px solid var(--confirmation-color);
}
rl-textarea textarea.validate.ng-invalid.ng-dirty {
  border: 1px solid var(--warning-color);
}
rl-textarea textarea:hover {
  border: 1px solid var(--general-border-color);
}
rl-textarea textarea:focus {
  border: 1px solid var(--general-text-inactive-color);
}
rl-textarea textarea:disabled {
  background: rgba(var(--background-highlight-color-rgb), 0.5);
  color: rgba(var(--general-text-color), 0.5);
  cursor: not-allowed;
}
rl-textarea textarea::placeholder {
  color: var(--general-text-color);
  opacity: 0.4;
}
rl-textarea span {
  position: absolute;
  bottom: 10px;
  right: 10px;
  opacity: 0.4;
  font-size: 10px;
}
rl-text-input {
  display: flex;
  width: 100%;
  position: relative;
}
rl-text-input input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 45px;
  padding: 0 10px;
  line-height: 45px;
  font-size: 15px;
  background: var(--background-highlight-color);
  color: var(--general-text-color);
  border: 1px solid transparent;
  border-radius: var(--general-border-radius);
}
rl-text-input input.validate.ng-valid.ng-dirty {
  border: 1px solid var(--confirmation-color);
}
rl-text-input input.validate.ng-invalid.ng-dirty {
  border: 1px solid var(--warning-color);
}
rl-text-input input:hover {
  border: 1px solid var(--general-border-color);
}
rl-text-input input:focus {
  border: 1px solid var(--general-text-inactive-color);
}
rl-text-input input:disabled {
  background: rgba(var(--background-highlight-color-rgb), 0.5);
  color: rgba(var(--general-text-color), 0.5);
  cursor: not-allowed;
}
rl-text-input input::placeholder {
  color: var(--general-text-color);
  opacity: 0.4;
}
rl-phone-input {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
}
rl-phone-input rl-select {
  flex: 0 0 auto;
  width: 120px;
}
rl-phone-input rl-select .options {
  min-width: 300px;
  max-width: 100%;
}
rl-phone-input rl-select[disabled] {
  cursor: not-allowed;
  background: inherit;
}
rl-phone-input rl-select[disabled] img {
  opacity: 0.5;
}
rl-phone-input rl-select[disabled] .select-area {
  color: var(--general-text-inactive-color);
  background-color: var(--locked-field-color);
}
rl-phone-input rl-select[disabled] .select-area span {
  opacity: 1;
}
rl-phone-input rl-text-input {
  flex: 1 1 auto;
  margin-left: 10px;
}
rl-phone-input rl-text-input input[disabled] {
  opacity: 1;
  color: var(--general-text-inactive-color);
  background-color: var(--locked-field-color);
  border-color: transparent;
}
rl-phone-input rl-icon {
  position: absolute;
  right: var(--m-spacing);
  color: var(--general-text-inactive-color);
  top: 50%;
  transform: translate(0, -50%);
}
html[dir="rtl"] rl-phone-input rl-text-input {
  margin-right: 10px;
  margin-left: 0;
}
html[dir="rtl"] rl-phone-input rl-icon {
  left: 10px;
  right: unset;
}
@media only screen and (min-width: 1025px) {
  rl-select-list {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
}
rl-select-list .item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}
rl-select-list .item .icon {
  margin-right: 10px;
}
rl-select-list .item .radio {
  color: var(--general-text-inactive-color);
}
rl-select-list .item .labels-container {
  display: flex;
  flex-direction: column;
}
rl-select-list .item .labels-container .title {
  margin-right: auto;
  line-height: 0.9rem;
}
rl-select-list .item .labels-container .help-text {
  font-size: 0.9rem;
  line-height: 0.9rem;
  font-weight: 500;
  color: var(--general-text-inactive-color);
}
@media only screen and (min-width: 1025px) {
  rl-select-list .item {
    cursor: pointer;
    height: 22px;
    line-height: 22px;
  }
  rl-select-list .item rl-icon {
    margin-right: 7px;
  }
  rl-select-list .item .title {
    font-size: 13px;
  }
}
rl-select-list .item.selected .title {
  color: var(--highlight-color);
  font-weight: 700;
}
rl-select-list .item.selected rl-icon {
  color: var(--highlight-color);
}
rl-select-list .item .checkbox-button rl-icon {
  font-size: 21px;
  color: var(--highlight-color);
}
rl-select-list .item.disabled {
  opacity: 0.5;
  pointer-events: none;
}
rl-toggle-button > div {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 10px;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 13px;
  color: var(--general-text-color);
  border-radius: var(--general-border-radius);
  background-color: var(--general-background-color);
  line-height: 20px;
}
rl-toggle-button > div.active {
  font-weight: bold;
  color: var(--text-on-highlight-color);
  background: var(--highlight-color);
}
rl-toggle-button > div rl-icon {
  margin-left: 5px;
  font-size: 15px;
}
action-popup .modal-close-icon {
  cursor: pointer;
  width: 20px;
  margin-left: auto;
}
action-popup .action-popup-wrapper {
  position: fixed;
  z-index: 201;
}
action-popup .action-popup-wrapper.show .action-popup-bg {
  display: block;
}
action-popup .action-popup-wrapper.show .action-popup {
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
}
@media only screen and (min-width: 1025px) {
  action-popup .action-popup-wrapper.show .action-popup {
    margin-left: calc(var(--sidemenu-width) / 2);
  }
}
action-popup .action-popup-bg {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  display: none;
}
action-popup .action-popup {
  z-index: 15;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1.1);
  border-radius: 7px;
  background: var(--elevated-background-color);
  box-shadow: 0 7px 15px rgba(0, 0, 0, 0.1);
  transition: 0.3s linear opacity, 0.15s linear transform;
  opacity: 0;
}
action-sheet-custom {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 500;
}
@media only screen and (min-width: 1025px) {
  action-sheet-custom {
    margin-left: var(--sidemenu-width);
  }
  action-sheet-custom[inline=true] {
    position: static;
    margin-left: 0;
  }
  action-sheet-custom[inline=true] .actionsheet-overlay {
    background-color: transparent;
    transition: none;
  }
  action-sheet-custom[inline=true] .actionsheet {
    top: 0;
    left: 0;
    transform: none;
    transition: 0.2s linear opacity;
    box-shadow: none;
    border: 1px solid var(--elevated-border-color);
  }
  action-sheet-custom[inline=true] .actionsheet.ng-enter,
  action-sheet-custom[inline=true] .actionsheet.ng-leave-active {
    transform: none;
  }
  action-sheet-custom[inline=true] .actionsheet > h4 {
    margin-bottom: 0;
    font-size: 9px;
    text-align: left;
    text-transform: uppercase;
    font-weight: bold;
    color: var(--elevated-text-inactive-color);
  }
}
action-sheet-custom .actionsheet-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.4);
  transition: 0.3s ease-in-out opacity;
}
action-sheet-custom .actionsheet-overlay.ng-enter,
action-sheet-custom .actionsheet-overlay.ng-leave-active {
  opacity: 0;
}
action-sheet-custom .actionsheet-overlay.ng-enter-active {
  opacity: 1;
}
action-sheet-custom .actionsheet {
  display: block;
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding-top: 25px;
  padding-bottom: env(safe-area-inset-bottom, 0px);
  border-radius: 15px 15px 0 0;
  background: var(--elevated-background-color);
  box-shadow: 0 6px 30px rgba(0, 0, 0, 0.15);
  transform: translate(0, 0);
  transition: 0.2s cubic-bezier(0, 1, 0.5, 1) transform;
}
action-sheet-custom .actionsheet.ng-enter,
action-sheet-custom .actionsheet.ng-leave-active {
  transform: translate(0, 100%);
}
@media only screen and (min-width: 1025px) {
  action-sheet-custom .actionsheet.ng-enter,
  action-sheet-custom .actionsheet.ng-leave-active {
    opacity: 0;
    transform: translate(-50%, -50%) scale(1.1);
  }
}
@media only screen and (min-width: 1025px) {
  action-sheet-custom .actionsheet {
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    border-radius: 15px;
    padding: var(--spacing);
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
    transition: 0.3s linear opacity, 0.15s linear transform;
  }
}
action-sheet-custom .actionsheet .sheet-close {
  position: absolute;
  top: 15px;
  right: 10px;
}
action-sheet-custom .actionsheet .sheet-close rl-icon {
  font-size: 23px;
}
action-sheet-custom .actionsheet .divider {
  position: relative;
  top: -15px;
  display: block;
  width: 42px;
  height: 5px;
  margin: 0 auto;
  border-radius: var(--general-border-radius);
  background: var(--elevated-text-color);
  opacity: 0.7;
}
@media only screen and (min-width: 1025px) {
  action-sheet-custom .actionsheet .divider {
    display: none;
  }
}
action-sheet-custom .actionsheet > h4 {
  padding-bottom: var(--m-spacing);
  margin: 0 0 calc(var(--m-spacing) / 2) 0;
  text-align: center;
  font-weight: 600;
  font-size: 15px;
  line-height: 1.3;
  color: var(--elevated-text-color);
}
@media only screen and (max-width: 1024px) {
  action-sheet-custom .actionsheet > h4 {
    border-bottom: 1px solid var(--elevated-border-color);
  }
}
action-sheet-custom .actionsheet .header-subtitle {
  font-size: 0.95rem;
  font-weight: 600;
  color: var(--elevated-text-inactive-color);
  padding: 0 var(--m-spacing);
}
action-sheet-custom .actionsheet .actionsheet-content {
  padding: var(--spacing);
  display: block;
}
@media only screen and (min-width: 1025px) {
  action-sheet-custom .actionsheet .actionsheet-content {
    padding: var(--spacing) 0 0 0;
  }
}
rl-select {
  display: block;
  position: relative;
  min-height: 45px;
  border-radius: var(--general-border-radius);
  background: var(--background-highlight-color);
}
rl-select .select-area {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 45px;
  box-sizing: border-box;
  padding: 0 30px 0 10px;
  border-radius: var(--general-border-radius);
  border: 1px solid transparent;
  font-size: 15px;
  line-height: 1.3;
  cursor: pointer;
  color: var(--general-text-color);
  background: var(--background-highlight-color);
  background-clip: padding-box;
}
rl-select .select-area > icon,
rl-select .select-area > img {
  width: 26px;
  height: 26px;
  display: block;
}
rl-select .select-area > rl-icon + span,
rl-select .select-area > icon + span,
rl-select .select-area > img + span,
rl-select .select-area activity-indicator + span {
  margin-left: 10px;
}
rl-select .select-area > span {
  margin-right: auto;
  margin-left: 0;
  line-height: 1.3;
  overflow: hidden;
  text-overflow: ellipsis;
}
rl-select .select-area.opened {
  border: 1px solid var(--general-text-inactive-color) !important;
}
rl-select .select-area.disabled {
  cursor: not-allowed;
}
rl-select .select-area.disabled > span {
  opacity: 0.5;
}
rl-select .select-area.disabled:after {
  display: none;
}
rl-select .select-area:hover {
  border: 1px solid var(--general-border-color);
}
rl-select .select-area.placeholder {
  color: rgba(var(--general-text-color-rgb), 0.4);
}
rl-select .select-area:after {
  content: '';
  display: block;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 5px 0 5px;
  border-color: var(--general-text-inactive-color) transparent transparent transparent;
}
rl-select .select-area .lock {
  position: absolute;
  right: var(--m-spacing);
  color: var(--general-text-inactive-color);
  top: 50%;
  transform: translate(0, -50%);
}
@media only screen and (max-width: 1024px) {
  rl-select .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: rgba(0, 0, 0, 0.4);
  }
}
rl-select .options {
  position: fixed;
  z-index: 2;
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid var(--general-border-color);
  background: var(--white-color);
  border-radius: 15px 15px 0 0;
  box-shadow: 0 7px 15px rgba(60, 72, 88, 0.1);
}
@media only screen and (max-width: 1024px) {
  rl-select .options {
    max-height: 40vh;
    z-index: 201;
    bottom: 0;
    left: 0;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-bottom: calc(15px + env(safe-area-inset-bottom));
    transform: translate(0, 0);
    transition: 0.2s cubic-bezier(0, 1, 0.5, 1) transform;
  }
  rl-select .options:after {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 10px;
    width: 42px;
    height: 5px;
    background: var(--text-on-white-color);
    opacity: 0.3;
  }
  rl-select .options.ng-hide,
  rl-select .options.ng-hide-add-active {
    transform: translate(0, 100%);
  }
}
@media only screen and (min-width: 1025px) {
  rl-select .options {
    position: absolute;
    max-height: 400px;
    top: calc(100% + 5px);
    border-radius: var(--general-border-radius);
    box-shadow: 0 6px 30px rgba(0, 0, 0, 0.15);
    transition: 0.15s linear opacity;
  }
  rl-select .options.top {
    top: auto;
    bottom: calc(100% + 5px);
  }
  rl-select .options.ng-enter {
    opacity: 0;
  }
  rl-select .options.ng-enter-active {
    opacity: 1;
  }
  rl-select .options.ng-leave {
    opacity: 1;
  }
  rl-select .options.ng-leave.ng-leave-active {
    opacity: 0;
  }
  rl-select .options.ng-hide {
    opacity: 0;
  }
}
rl-select .options .scroll-container {
  flex: 1;
  overflow-y: auto;
  overscroll-behavior-y: contain;
}
rl-select .options .option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 45px;
  padding: 0 15px;
  cursor: pointer;
}
rl-select .options .option.selected rl-icon {
  color: var(--highlight-color);
}
rl-select .options .option.selected > span {
  color: var(--highlight-color);
  font-weight: bold;
}
rl-select .options .option.selected .radio {
  position: relative;
  border: 2px solid var(--highlight-color);
}
rl-select .options .option.selected .radio:after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 8px;
  height: 8px;
  background: var(--highlight-color);
  border-radius: 50%;
}
rl-select .options .option > icon,
rl-select .options .option > img {
  width: 26px;
  height: 26px;
  display: block;
}
rl-select .options .option > rl-icon + span,
rl-select .options .option > icon + span,
rl-select .options .option > img + span,
rl-select .options .option activity-indicator + span {
  margin-left: 10px;
}
rl-select .options .option > span {
  margin-right: auto;
  margin-left: 0;
  color: var(--text-on-white-color);
  overflow: hidden;
  text-overflow: ellipsis;
}
rl-select .options .option .radio {
  width: 18px;
  height: 18px;
  border-radius: 100%;
  flex: 0 0 auto;
  border: 2px solid var(--general-border-color);
}
rl-select .options .option:hover {
  background: var(--background-highlight-color);
}
rl-rich-textarea {
  display: block;
  position: relative;
  background-color: var(--general-background-color);
  min-height: 251px;
  border-top: 1px solid var(--general-border-color);
  border-bottom: 1px solid var(--general-border-color);
}
rl-rich-textarea button[data-cmd="viewImage"],
rl-rich-textarea button[data-cmd="editImage"] {
  display: none;
}
rl-rich-textarea .rich-toolbar {
  position: sticky;
  top: 0;
  z-index: 10;
  width: 100%;
}
rl-rich-textarea .fr-box.fr-basic {
  height: 100%;
  display: flex;
  flex-direction: column;
}
rl-rich-textarea .fr-box.fr-basic .fr-wrapper {
  flex: 1;
  border: none;
  background-color: var(--general-background-color);
  border-radius: 0;
}
rl-rich-textarea .fr-box.fr-basic .fr-wrapper .fr-placeholder {
  color: rgba(var(--general-text-color-rgb), 0.4);
}
rl-rich-textarea .fr-box.fr-basic .fr-element {
  min-height: 250px;
  padding: 10px;
  line-height: 1.3;
  color: var(--general-text-color);
}
rl-rich-textarea .fr-box.fr-basic .fr-element h1 {
  margin: 0.8rem 0 0.3rem 0;
  line-height: 1.1;
  font-size: 2rem;
}
rl-rich-textarea .fr-box.fr-basic .fr-element h2 {
  margin: 0.8rem 0 0.3rem 0;
  line-height: 1.1;
  font-size: 1.5rem;
}
rl-rich-textarea .fr-box.fr-basic .fr-element h3 {
  margin: 0.8rem 0 0.3rem 0;
  line-height: 1.1;
  font-size: 1.3rem;
}
rl-rich-textarea .fr-box.fr-basic .fr-element h4 {
  margin: 0.8rem 0 0.3rem 0;
  line-height: 1.1;
  font-size: 1rem;
}
rl-rich-textarea .fr-box.fr-basic .fr-element p {
  font-size: 15px;
  padding: 0;
  margin: 0 0 10px 0;
}
rl-rich-textarea .fr-box.fr-basic .fr-element ul,
rl-rich-textarea .fr-box.fr-basic .fr-element ol {
  margin-block-start: 1rem;
  margin-block-end: 1rem;
}
rl-rich-textarea .fr-box.fr-basic .fr-element table td,
rl-rich-textarea .fr-box.fr-basic .fr-element table th {
  border-color: var(--general-border-color);
}
rl-rich-textarea .fr-box.fr-basic .fr-element table td.fr-selected-cell,
rl-rich-textarea .fr-box.fr-basic .fr-element table th.fr-selected-cell {
  border-color: var(--highlight-color);
}
rl-rich-textarea .fr-box.fr-basic .fr-dropdown-wrapper {
  max-height: 200px !important;
}
rl-rich-textarea .fr-box.fr-basic .fr-layer {
  margin: 10px 20px;
}
rl-rich-textarea .fr-view .fr-video {
  margin: 0 5px;
}
rl-rich-textarea .fr-view .fr-video.fr-dvi.fr-fvl {
  margin-right: 15px;
}
rl-rich-textarea .fr-placeholder {
  color: rgba(var(--general-background-color-rgb));
}
rl-rich-textarea .fr-second-toolbar {
  display: none;
}
rl-rich-textarea .fr-selected-color {
  font-family: 'Font Awesome 5 Pro' !important;
}
rl-rich-textarea .field-postfix {
  display: none;
}
.fr-popup {
  z-index: 202 !important;
  background: var(--general-background-color);
  color: var(--general-text-color);
}
.fr-popup .fr-buttons.fr-tabs {
  background: var(--general-background-color);
}
.fr-popup .fr-buttons .fr-btn {
  color: var(--general-text-color);
}
.fr-popup .fr-buttons .fr-btn.fr-dropdown:after {
  border-top-color: var(--general-text-color);
  border-bottom-color: var(--general-text-color);
}
.fr-popup .fr-buttons .fr-btn svg path {
  fill: var(--general-text-color);
}
.fr-popup .fr-selected-set {
  padding: 15px !important;
}
.fr-popup .fr-selected-set > span {
  width: 35px;
  height: 35px;
}
.fr-popup .fr-link-insert-layer .fr-input-line {
  padding: 0;
  margin-bottom: 15px;
}
.fr-popup .fr-link-insert-layer .fr-input-line input.fr-not-empty + label {
  top: -7px;
}
.fr-popup .fr-link-insert-layer .fr-input-line label {
  top: 8px;
}
.fr-popup .fr-link-insert-layer .fr-checkbox-line {
  float: left;
}
.fr-popup .fr-link-insert-layer .fr-checkbox {
  padding: 0;
}
.fr-popup .fr-video-by-url-layer {
  margin-top: 0;
}
.fr-popup .fr-video-embed-layer .fr-input-line {
  padding: 0;
}
.fr-popup .fr-table-colors {
  padding: 15px !important;
}
.fr-popup .fr-table-size .fr-select-table-size > span > span {
  border-color: var(--general-border-color);
}
.fr-popup .fr-dropdown-menu {
  background-color: var(--general-background-color) !important;
}
.fr-toolbar {
  background: var(--general-background-color);
  color: var(--general-text-color);
  border: 0;
  border-radius: 0 !important;
}
.fr-toolbar .fr-more-toolbar {
  background: var(--background-highlight-color) !important;
}
.fr-toolbar .fr-newline {
  margin: 0;
  background: var(--general-border-color) !important;
}
.fr-toolbar .fr-dropdown-menu {
  background-color: var(--general-background-color) !important;
}
@media only screen and (max-width: 767px) {
  .fr-toolbar .fr-btn-grp {
    margin: 0;
  }
}
.fr-toolbar .fr-command.fr-btn {
  color: var(--general-text-color);
  fill: var(--general-text-color);
  border-left: none !important;
}
.fr-toolbar .fr-command.fr-btn.fr-dropdown:after,
.fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active:after {
  border-top-color: var(--general-text-color);
  border-bottom-color: var(--general-text-color);
}
.fr-toolbar .fr-command.fr-btn.fr-active {
  background: var(--background-highlight-color) !important;
}
.fr-toolbar .fr-command.fr-btn.fr-active svg path {
  fill: var(--highlight-color) !important;
}
.fr-toolbar .fr-command.fr-btn.fr-open {
  background: var(--background-highlight-color) !important;
}
.fr-toolbar .fr-command.fr-btn svg path {
  fill: var(--general-text-color);
}
.fr-separator {
  background: var(--general-border-color);
}
rl-rich-preview {
  display: block;
}
rl-rich-preview .placeholder-shimmer {
  height: 19px;
}
rl-rich-preview .content {
  width: 100%;
  transition: max-height 0.2s ease-out;
}
rl-rich-preview .content.loading {
  visibility: hidden;
  position: absolute;
  overflow: hidden;
  max-height: 90px;
  top: 0;
}
rl-rich-preview .content.read-more-enabled {
  position: relative;
  overflow: hidden;
}
@media only screen and (max-width: 1024px) {
  rl-rich-preview .content.read-more-enabled {
    max-height: 90px;
  }
}
@media only screen and (min-width: 768px) {
  rl-rich-preview .content.read-more-enabled {
    max-height: 180px;
  }
}
rl-rich-preview .content.read-more-enabled.closed:after {
  content: '';
  z-index: 1;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -10px;
  height: 30px;
  background: linear-gradient(180deg, rgba(var(--general-background-color-rgb), 0) 0%, var(--general-background-color) 80%);
}
rl-rich-preview .content .text h1 {
  margin: 0.8rem 0 0.3rem 0;
  line-height: 1.1;
  font-size: 25px;
  font-weight: 700;
}
rl-rich-preview .content .text h2 {
  margin: 0.8rem 0 0.3rem 0;
  line-height: 1.1;
  font-size: 23px;
  font-weight: 700;
}
rl-rich-preview .content .text h3 {
  margin: 0.8rem 0 0.3rem 0;
  line-height: 1.1;
  font-size: 21px;
  font-weight: 700;
}
rl-rich-preview .content .text h4 {
  margin: 0.8rem 0 0.3rem 0;
  line-height: 1.1;
  font-size: 19px;
  font-weight: 700;
}
rl-rich-preview .content .text h5 {
  margin: 0.8rem 0 0.3rem 0;
  font-size: 17px;
  font-weight: 700;
}
rl-rich-preview .content .text h6 {
  margin: 0.8rem 0 0.3rem 0;
  font-size: 15px;
  font-weight: 700;
}
rl-rich-preview .content .text pre {
  margin: 0.8rem 0 0.3rem 0;
  color: var(--general-text-inactive-color);
  font-weight: 400;
  font-size: 15px;
  padding: 15px;
  border: 1px solid var(--general-border-color);
  border-radius: 7px;
  white-space: pre-line;
  background-color: var(--background-highlight-color);
  display: inline-block;
  max-width: 100%;
}
rl-rich-preview .content .text p {
  font-size: 15px;
  font-weight: 400;
  padding: 0;
  margin: 0 0 10px 0;
}
rl-rich-preview .content .text p.p-top {
  margin-top: 10px;
}
rl-rich-preview .content .text p:last-of-type {
  margin: 0;
}
rl-rich-preview .content .text hash-tag {
  color: var(--highlight-color);
  font-size: 15px;
  font-weight: 700;
}
rl-rich-preview .content .text a,
rl-rich-preview .content .text user-mention,
rl-rich-preview .content .text department-mention,
rl-rich-preview .content .text user-group-mention {
  padding: 5px 8px;
  color: var(--general-text-color);
  background-color: var(--background-highlight-color);
  border-radius: 4px;
  overflow: hidden;
  white-space: nowrap;
  border: 1px solid var(--general-border-color);
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 100%;
  font-weight: 400 !important;
  vertical-align: middle;
}
rl-rich-preview .content .text user-mention,
rl-rich-preview .content .text department-mention,
rl-rich-preview .content .text user-group-mention {
  color: var(--general-text-inactive-color) !important;
}
rl-rich-preview .content .text a.btn {
  background-color: var(--button-background-color);
  color: var(--button-text-color);
  display: flex;
  align-items: center;
  text-decoration: none;
  justify-content: center;
}
rl-rich-preview .content .text a.btn:before {
  content: none;
}
rl-rich-preview .content .text a:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f0c1";
  display: block;
  width: 16px;
  height: 16px;
  float: left;
  margin: 0 6px 0 0;
}
rl-rich-preview .content .text ul,
rl-rich-preview .content .text ol {
  padding-inline-start: 20px;
  margin-block-start: 1rem;
  margin-block-end: 1rem;
}
rl-rich-preview .content .text ul li:not(:last-of-type),
rl-rich-preview .content .text ol li:not(:last-of-type) {
  margin-bottom: 15px;
}
rl-rich-preview .content .text ol {
  padding-inline-start: 40px;
}
rl-rich-preview .content .text ul li::marker {
  color: var(--general-text-inactive-color);
}
rl-rich-preview .content .text:not(:has(> br:last-child)) {
  padding-bottom: 20px;
}
rl-rich-preview .content .text video {
  background: grey;
}
rl-rich-preview .content .text hash-tag {
  color: var(--highlight-color);
  font-weight: bold;
}
rl-rich-preview .content .text user-mention,
rl-rich-preview .content .text department-mention,
rl-rich-preview .content .text hash-tag {
  cursor: pointer;
}
rl-rich-preview .content .text .table-wrapper {
  overflow-x: auto;
}
rl-rich-preview .content .text .table-wrapper table td,
rl-rich-preview .content .text .table-wrapper table th {
  border: 1px solid var(--general-border-color);
}
rl-rich-preview .content .text media {
  position: relative;
  max-width: 100%;
}
rl-rich-preview .content .text media.fr-dib {
  margin: 5px auto;
  display: block;
  float: none;
  vertical-align: top;
}
rl-rich-preview .content .text media.fr-dib:not(.fr-fil):not(.fr-fir) {
  text-align: center;
}
rl-rich-preview .content .text media.fr-dib > .media {
  display: inline-block;
}
rl-rich-preview .content .text media.fr-dib.fr-fil {
  margin-left: 0;
  text-align: left;
}
rl-rich-preview .content .text media.fr-dib.fr-fir {
  margin-right: 0;
  text-align: right;
}
rl-rich-preview .content .text media.fr-dii {
  display: inline-block;
  float: none;
  vertical-align: bottom;
  margin-left: 5px;
  margin-right: 5px;
  max-width: calc(100% - (2 * 5px));
}
rl-rich-preview .content .text media.fr-dii.fr-fil {
  float: left;
  margin: 5px 15px 5px 0;
  max-width: calc(100% - 5px);
}
rl-rich-preview .content .text media.fr-dii.fr-fir {
  float: right;
  margin: 5px 0 5px 5px;
  max-width: calc(100% - 5px);
}
rl-rich-preview .content .text media.fr-video.fr-dvi {
  margin: 0 5px;
}
rl-rich-preview .content .text media.fr-video.fr-dvi.fr-fvl {
  margin-right: 15px;
}
rl-rich-preview .content .text media.fr-video[resize-width] > * {
  display: inline-block;
}
rl-rich-preview .content .text media img {
  display: inline-block;
}
rl-rich-preview .read-more {
  width: 100%;
  margin: 10px 0;
  height: 30px;
  line-height: 30px;
  color: var(--general-text-color);
  background: none;
  border: none;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  opacity: 0.5;
}
translation-button {
  display: block;
}
translation-button button {
  display: flex;
  align-items: center;
  padding: 0;
  color: var(--highlight-color);
  border: none;
  font-weight: 700;
  -webkit-appearance: none;
  cursor: pointer;
  height: 20px;
  line-height: 20px;
}
translation-button button:hover,
translation-button button:active {
  opacity: 0.9;
}
translation-button .spinner {
  margin-left: 6.25px;
  width: 20px;
  height: 20px;
  border-color: var(--highlight-color);
  opacity: 0.8;
}
translation-button rl-icon {
  margin-right: 6.25px;
  font-size: 20px;
}
rl-loading-placeholder .placeholder-shimmer {
  display: block;
  height: 30px;
  min-width: 10px;
  border-radius: 4px;
}
rl-loading-placeholder .placeholder-shimmer:not(:last-of-type) {
  margin-bottom: 10px;
}
rl-loading-placeholder .placeholder-shimmer.no-animation {
  animation: none;
  background-color: rgba(var(--general-text-color-rgb), 0.15);
  background-image: none;
}
rl-loading-placeholder.invert .placeholder-shimmer {
  background-image: linear-gradient(to right, rgba(var(--general-text-color-rgb), 0.25) 4%, rgba(var(--general-text-color-rgb), 0.15) 25%, rgba(var(--general-text-color-rgb), 0.25) 36%);
}
rl-loading-placeholder.invert .placeholder-shimmer.no-animation {
  animation: none;
  background-color: rgba(var(--general-text-color-rgb), 0.25);
  background-image: none;
}
rl-search-result-item {
  display: flex;
  gap: var(--m-spacing);
}
rl-search-result-item .image {
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: var(--general-border-radius);
}
@media only screen and (min-width: 1025px) {
  rl-search-result-item .image {
    width: 40px;
    height: 40px;
  }
}
rl-search-result-item .image file-preview {
  width: 100%;
  height: 100%;
  border-radius: var(--general-border-radius);
  overflow: hidden;
}
rl-search-result-item .image file-preview .background:not(.image):after {
  border-radius: var(--general-border-radius);
}
rl-search-result-item .image file-preview .background.no-preview:after {
  display: none;
}
rl-search-result-item .image file-preview .file-icon {
  font-size: 1.5rem;
}
rl-search-result-item .image file-preview .file-icon[icon="play"] {
  width: 2rem;
  height: 2rem;
  font-size: 1.3rem;
}
rl-search-result-item .image file-preview .file-icon[icon="play"] span {
  margin-left: 3px;
}
@media only screen and (min-width: 1025px) {
  rl-search-result-item .image file-preview .file-icon {
    font-size: 1.3rem;
  }
  rl-search-result-item .image file-preview .file-icon[icon="play"] {
    width: 1.5rem;
    height: 1.5rem;
    font-size: 1rem;
  }
}
rl-search-result-item .image > rl-icon {
  color: var(--general-text-inactive-color);
}
rl-search-result-item .image:not(.has-media) {
  background-color: var(--general-border-color);
}
rl-search-result-item .image:not(.has-media) > rl-icon {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  font-size: 1.5rem;
}
@media only screen and (min-width: 1025px) {
  rl-search-result-item .image:not(.has-media) > rl-icon {
    font-size: 1.25rem;
  }
}
rl-search-result-item .content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 3px;
  flex: 1;
  overflow: hidden;
  margin-bottom: auto;
}
rl-search-result-item .content-wrapper .item-title {
  display: flex;
  overflow: hidden;
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
}
rl-search-result-item .content-wrapper .item-title bdi:last-of-type {
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  width: 100%;
  white-space: nowrap;
}
rl-search-result-item .content-wrapper .meta {
  display: flex;
  white-space: nowrap;
}
rl-search-result-item .content-wrapper .meta .name,
rl-search-result-item .content-wrapper .meta .path {
  overflow: hidden;
  text-overflow: ellipsis;
}
rl-search-result-item .content-wrapper .meta span {
  font-size: 0.875rem;
}
rl-search-result-item .content-wrapper .meta span:not(:first-child)::before {
  content: '·';
  font-weight: bold;
  padding: 0 3px;
}
rl-search-result-item .content-wrapper .content {
  margin: 0;
  font-size: 0.875rem;
}
rl-search-result-item .content-wrapper .content .teaser {
  display: none;
}
@media only screen and (max-width: 767px) {
  rl-search-result-item .content-wrapper .content .teaser.short {
    display: inline-block;
  }
}
@media only screen and (min-width: 768px) {
  rl-search-result-item .content-wrapper .content .teaser:not(.short) {
    display: inline-block;
  }
}
rl-search-result-item .content-wrapper .content em {
  padding: 0 2px;
  background-color: rgba(var(--highlight-color-rgb), 0.15);
  border-radius: 4px;
  font-weight: 700;
  font-style: normal;
}
rl-search-result-item .content-wrapper.catalog-category {
  margin-bottom: unset;
  justify-content: center;
}
rl-search-result-item .content-wrapper.catalog-category .item-title {
  align-items: flex-start;
  overflow: hidden;
  margin: 0;
  gap: 5px;
  font-weight: 600;
}
rl-search-result-item .breadcrumbs-list {
  margin: 0;
  padding: 0;
  display: flex;
  gap: 5px;
  list-style: none;
  line-height: 130%;
}
rl-search-result-item .breadcrumbs-list li {
  color: var(--general-text-inactive-color);
  font-size: 0.85rem;
}
rl-search-result-item .breadcrumbs-list rl-icon {
  width: 0.8rem;
  font-size: 0.8rem;
}
.search-result-placeholder {
  display: flex;
  gap: var(--m-spacing);
  margin-bottom: var(--spacing);
}
.search-result-placeholder .image {
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  border-radius: var(--general-border-radius);
}
@media only screen and (min-width: 1025px) {
  .search-result-placeholder .image {
    width: 40px;
    height: 40px;
  }
}
.search-result-placeholder .text {
  flex-basis: 100%;
}
.search-result-placeholder .text .placeholder-shimmer {
  display: block;
  height: 10px;
  width: 100%;
  margin-bottom: 6px;
  border-radius: var(--general-border-radius);
}
.search-result-placeholder .text .title .placeholder-shimmer {
  height: 22px;
}
.search-result-placeholder .text .meta .placeholder-shimmer {
  margin-top: var(--m-spacing);
}
.search-result-placeholder.social .title {
  display: none;
}
.search-result-placeholder.catalog .meta {
  display: none;
}
rl-date-filter {
  position: relative;
  display: inline-block;
}
@media only screen and (min-width: 1025px) {
  rl-date-filters-popup action-sheet-custom .actionsheet {
    width: 320px;
    margin-top: 50px;
    padding: 10px;
  }
}
rl-date-filters-popup action-sheet-custom .actionsheet .actionsheet-content {
  padding-top: 0;
}
@media only screen and (min-width: 1025px) {
  rl-date-filters-popup rl-select-list {
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
  rl-date-filters-popup rl-select-list .item {
    flex-direction: row-reverse;
    justify-content: flex-end;
    flex-basis: 50%;
  }
}
@media only screen and (min-width: 1025px) {
  rl-date-filters-popup .custom-date {
    margin-top: 7px;
  }
}
rl-date-filters-popup .custom-date .date-range-wrapper {
  margin-bottom: 20px;
}
rl-date-filters-popup .custom-date label {
  padding: 7px 7px 3px 0;
  font-size: 9px;
  text-transform: uppercase;
  color: var(--general-text-inactive-color);
}
rl-date-filters-popup .custom-date .date-range {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  border-radius: var(--general-border-radius);
  border: 1px solid var(--general-border-color);
  background-color: var(--background-highlight-color);
}
rl-date-filters-popup .custom-date .date-range input {
  position: relative;
  height: 32px;
  padding: 0 10px;
  width: calc(50% - 35px / 2);
  font-size: 13px;
  color: var(--background-highlight-color);
  border: none;
  background-color: transparent;
  --webkit-appearence: none;
  box-sizing: border-box;
}
rl-date-filters-popup .custom-date .date-range input:focus,
rl-date-filters-popup .custom-date .date-range input:valid {
  color: var(--general-text-color);
}
rl-date-filters-popup .custom-date .date-range input:focus:before,
rl-date-filters-popup .custom-date .date-range input:valid:before {
  display: none;
}
rl-date-filters-popup .custom-date .date-range input:before {
  color: var(--general-text-inactive-color);
  content: attr(placeholder);
  position: absolute;
  top: 7px;
}
@media only screen and (max-width: 767px) {
  rl-date-filters-popup .custom-date .date-range input:before {
    width: 150px;
  }
}
rl-date-filters-popup .custom-date .date-range rl-icon {
  padding: 0 10px;
  align-self: center;
  font-size: 12px;
  color: var(--general-text-inactive-color);
}
rl-date-filters-popup .custom-date .apply button {
  font-weight: 400;
}
@media only screen and (min-width: 1025px) {
  rl-date-filters-popup .custom-date .apply button {
    height: 36px;
    font-size: 12px;
  }
}
rl-date-filters-popup .clear button {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  color: var(--warning-color);
}
@media only screen and (min-width: 1025px) {
  rl-date-filters-popup .clear button {
    height: 40px;
    font-size: 12px;
  }
  rl-date-filters-popup .clear button rl-icon {
    font-size: 14px;
  }
}
rl-date-filters-popup .clear button rl-icon {
  margin-right: 7px;
  font-size: 14px;
}
rl-sorting {
  display: block;
  position: relative;
}
rl-sorting > button {
  padding: 0;
}
rl-sorting.hidden {
  display: none;
}
@media only screen and (min-width: 1025px) {
  rl-sorting-popup action-sheet-custom .actionsheet {
    width: 160px;
    margin-top: 27px;
    padding: 10px;
    right: 0;
    left: auto !important;
  }
  rl-sorting-popup action-sheet-custom .actionsheet .icon {
    display: none;
  }
  rl-sorting-popup action-sheet-custom .actionsheet rl-select-list .item {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
}
rl-sorting-popup action-sheet-custom .actionsheet .actionsheet-content {
  padding-top: 0;
}
empty-search-results .help-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 90px auto 0;
  max-width: 250px;
  text-align: center;
}
empty-search-results .help-text rl-icon {
  position: relative;
  font-size: 56px;
  margin-bottom: 20px;
  color: var(--general-text-inactive-color);
}
empty-search-results .help-text h4 {
  margin: 0;
  font-size: 15px;
  font-weight: 700;
}
empty-search-results .help-text p {
  margin: 0;
  font-size: 13px;
}
@media only screen and (min-width: 1025px) {
  empty-search-results .help-text {
    max-width: 320px;
  }
  empty-search-results .help-text h4 {
    margin-bottom: 10px;
  }
  empty-search-results .help-text rl-icon {
    margin-bottom: 25px;
  }
}
rl-translation-information .translation-information {
  display: flex;
  flex-direction: column;
  padding: 15px;
  border-radius: var(--training-border-radius);
  background-color: rgba(var(--highlight-color-rgb), 0.2);
}
rl-translation-information .translation-button {
  display: inline-flex;
  align-self: end;
  align-items: flex-end;
  gap: calc(var(--m-spacing) / 2);
  margin-top: var(--m-spacing);
  color: var(--highlight-color);
}
rl-translation-information .translation-button > span {
  font-weight: 600;
}
prev-next-buttons {
  display: inline-flex;
  filter: drop-shadow(0 6px 15px var(--shadow-color));
  background: var(--elevated-background-color);
  border-radius: var(--general-border-radius);
}
prev-next-buttons button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 46px;
  padding: 0;
  color: var(--elevated-text-color);
  background: var(--elevated-background-color);
  border-radius: var(--general-border-radius);
  border: 1px solid var(--elevated-border-color);
}
prev-next-buttons button:not([disabled]) {
  cursor: pointer;
}
prev-next-buttons button[disabled] {
  color: var(--elevated-text-inactive-color);
}
prev-next-buttons button.prev {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
prev-next-buttons button.next,
prev-next-buttons button.submit:not(.submit-only) {
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
prev-next-buttons button.submit {
  width: auto;
  padding: 0 25px;
  font-weight: bold;
  background: var(--highlight-color);
  color: var(--text-on-highlight-color);
}
prev-next-buttons button.submit[disabled] {
  background: var(--elevated-background-color);
  color: var(--elevated-text-inactive-color);
}
prev-next-buttons button.btn-close {
  width: auto;
  padding: 0 25px;
  font-weight: bold;
}
prev-next-buttons.no-steps button.submit {
  border-top-left-radius: var(--general-border-radius);
  border-bottom-left-radius: var(--general-border-radius);
}
rl-toggle-filters {
  position: relative;
  display: inline-block;
}
@media only screen and (min-width: 1025px) {
  rl-toggle-filters-popup action-sheet-custom .actionsheet {
    margin-top: 50px;
    padding: 10px;
    width: fit-content;
  }
}
rl-toggle-filters-popup action-sheet-custom .actionsheet .actionsheet-content {
  padding-top: 0;
}
@media only screen and (min-width: 1025px) {
  rl-toggle-filters-popup rl-select-list {
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
  rl-toggle-filters-popup rl-select-list .item {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
  rl-toggle-filters-popup rl-select-list .item .title {
    white-space: nowrap;
  }
  rl-toggle-filters-popup rl-select-list .item .icon {
    display: none;
  }
}
rl-accordion .accordion-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing);
}
rl-accordion-item {
  display: flex;
  flex-direction: column;
  padding: var(--m-spacing) var(--spacing);
  gap: calc(var(--m-spacing) * 2);
  border-radius: var(--general-border-radius);
  box-shadow: 0 7px 15px 0 var(--shadow-color);
  background: var(--general-background-color);
}
rl-accordion-item.opening .accordion-item-body {
  display: block;
  opacity: 0;
}
rl-accordion-item.opened [icon=arrow-top] {
  display: inline-flex;
}
rl-accordion-item.opened .accordion-item-body {
  display: block;
  opacity: 1;
}
rl-accordion-item:not(.opened) [icon=arrow-bottom] {
  display: inline-flex;
}
rl-accordion-item .accordion-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
rl-accordion-item .accordion-item-header [ng-transclude] {
  flex: 1;
}
rl-accordion-item .accordion-item-header rl-icon {
  display: none;
  background: var(--general-border-color);
  box-shadow: 0 7px 15px 0 var(--shadow-color);
}
rl-accordion-item .accordion-item-body {
  display: none;
  transition: opacity 0.3s ease-in-out;
}
.no-content {
  height: 200px;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  margin-top: -100px;
}
.no-content i {
  display: block;
  margin: 0 auto 20px;
  text-align: center;
  font-size: 100px;
  padding: 0;
}
.no-content > span {
  display: block;
  margin: 0;
  text-align: center;
  font-weight: bold;
  font-size: 17px;
}
.no-content rl-icon {
  display: block;
  margin: 0;
  text-align: center;
  font-size: 64px;
  color: var(--general-text-inactive-color);
}
.no-content p {
  display: block;
  margin: 0 auto;
  padding: 0;
  max-width: 200px;
  text-align: center;
}
rl-button button {
  min-width: 150px;
  height: 45px;
  padding: 0 10px;
  font-size: 15px;
  font-weight: 600;
  -webkit-appearance: none;
  background: none;
  border: none;
  border-radius: var(--general-border-radius);
  opacity: 1;
  transition: opacity 0.3s ease;
}
rl-button button:active:not([disabled]),
rl-button button:hover:not([disabled]) {
  opacity: 0.9;
}
rl-button button.round {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 50px;
  height: 50px;
  min-width: 50px;
  border-radius: 50%;
}
rl-button button.standard {
  color: var(--button-text-color);
  background: var(--button-background-color);
}
rl-button button.standard.secondary,
rl-button button.standard[disabled] {
  background: rgba(var(--general-text-color-rgb), 0.25);
  color: rgba(var(--general-text-color-rgb), 0.65);
}
rl-button button.outline {
  color: var(--general-text-color);
  border: 1px solid currentColor;
}
rl-button button.outline.secondary,
rl-button button.outline[disabled] {
  opacity: 0.5;
}
rl-button button.highlight {
  color: var(--highlight-color);
}
rl-button button.transparent {
  color: var(--highlight-color);
  border: none;
}
rl-button button.transparent.secondary,
rl-button button.transparent[disabled] {
  color: var(--general-text-color);
  opacity: 0.5;
}
rl-button button.full-width {
  width: 100%;
}
rl-button button.full-width button {
  width: 100%;
}
rl-button button.small {
  height: 40px;
  padding: 0 5px;
  min-width: 100px;
  font-weight: 500;
}
rl-button button.link {
  height: auto;
  min-width: auto;
  padding: 0;
}
rl-button button.upper {
  text-transform: uppercase;
}
rl-button button rl-icon.left {
  margin-right: 5px;
}
rl-button button rl-icon.right {
  margin-left: 5px;
}
rl-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  font-size: 19px;
}
rl-icon.circle {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 18px;
}
rl-icon.circle.small {
  width: 19px;
  height: 19px;
  font-size: 11px;
}
rl-icon .svg-teams path {
  fill: var(--general-text-color);
}
/* global styles */
input.rl-input {
  width: 100%;
  height: 45px;
  box-sizing: border-box;
  padding: 10px;
  border-radius: var(--general-border-radius);
  border: 1px solid transparent;
  font-size: 15px;
  line-height: 1.3;
  color: var(--general-text-color);
  background: var(--background-highlight-color);
  background-clip: padding-box;
}
input.rl-input.ng-valid-required.ng-dirty,
input.rl-input .ng-valid.ng-dirty {
  border: 1px solid var(--confirmation-color);
}
input.rl-input.ng-invalid.ng-dirty {
  border: 1px solid var(--warning-color);
}
input.rl-input:hover {
  border: 1px solid var(--general-border-color);
}
input.rl-input:focus {
  border: 1px solid var(--general-text-inactive-color);
}
input.rl-input::placeholder {
  color: var(--general-text-color);
  opacity: 0.4;
}
rl-rich-textarea.rl-input {
  background-color: var(--background-highlight-color);
  border-radius: var(--general-border-radius);
  border: 0;
}
rl-rich-textarea.rl-input .fr-toolbar {
  background-color: var(--background-highlight-color);
  border-radius: var(--general-border-radius) !important;
}
rl-rich-textarea.rl-input .fr-box.fr-basic .fr-wrapper {
  background: transparent;
}
rl-rich-textarea.rl-input .fr-toolbar .fr-more-toolbar.fr-expanded {
  border-top: 1px solid var(--general-border-color);
  border-bottom: 1px solid var(--general-border-color);
}
rl-rich-textarea.rl-input .fr-toolbar .fr-command.fr-btn.fr-open {
  border: 1px solid var(--general-border-color) !important;
}
select.rl-select {
  width: 100%;
  height: 45px;
  box-sizing: border-box;
  padding: 10px;
  border-radius: var(--general-border-radius);
  border: 1px solid transparent;
  font-size: 15px;
  line-height: 1.3;
  color: var(--general-text-color);
  background: var(--background-highlight-color);
  background-clip: padding-box;
}
select.rl-select.ng-valid-required.ng-dirty,
select.rl-select.ng-valid.ng-dirty {
  border: 1px solid var(--confirmation-color);
}
select.rl-select.ng-invalid.ng-dirty {
  border: 1px solid var(--warning-color);
}
select.rl-select:hover {
  border: 1px solid var(--general-border-color);
}
select.rl-select:focus {
  border: 1px solid var(--general-text-inactive-color);
}
select.rl-select::placeholder {
  color: var(--general-text-color);
  opacity: 0.4;
}
label.rl-label {
  display: inline-block;
  padding: 5px 10px;
  font-size: 11px;
  line-height: 1.3;
  font-weight: normal;
  text-transform: uppercase;
  color: var(--general-text-inactive-color);
}
.rl-field-wrapper {
  margin-bottom: 17px;
}
.rl-field-wrapper.horizontal {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.rl-field-group {
  padding: 10px;
  background: var(--background-highlight-color);
  border-radius: var(--general-border-radius);
}
.rl-field-group .rl-field-wrapper:last-of-type {
  margin-bottom: 0;
}
.rl-form.show-validation multiple-permission-selector-wrapper.ng-invalid .permissions-label-wrapper,
.rl-form.show-validation multiple-permission-selector-wrapper.ng-invalid .permissions-set {
  border-color: var(--alert-color);
}
.rl-form.show-validation multiple-permission-selector-wrapper.ng-invalid .permission-selector-label {
  background: var(--alert-color);
}
.rl-form.show-validation task-assignees.ng-invalid .assignees-label {
  background: var(--alert-color);
}
.rl-form.show-validation .rl-field.ng-invalid > label,
.rl-form.show-validation .rl-field-group.ng-invalid > label {
  background: var(--alert-color);
}
.rl-form.show-validation .rl-field.ng-invalid > .rl-input,
.rl-form.show-validation .rl-field-group.ng-invalid > .rl-input {
  border-color: var(--alert-color);
}
.rl-form.show-validation .rl-field.ng-invalid > .rl-validation-message,
.rl-form.show-validation .rl-field-group.ng-invalid > .rl-validation-message {
  display: block;
  padding: 10px 10px 0 10px;
  font-size: 13px;
  background: var(--general-background-color);
  color: var(--alert-color);
}
.rl-form.show-validation .rl-field.ng-invalid > .rl-validation-message:before,
.rl-form.show-validation .rl-field-group.ng-invalid > .rl-validation-message:before {
  content: '*';
}
.rl-form .rl-field {
  display: flex;
  flex-direction: column;
}
.rl-form .hint {
  display: block;
  padding: 6px 10px;
  opacity: 0.4;
  font-size: 13px;
}
.rl-form .rl-field-group {
  display: flex;
  flex-direction: column;
  padding: 0;
}
.rl-form .rl-field-group .rl-input:not(:last-of-type) {
  border-bottom-color: var(--general-background-color);
}
.rl-form .rl-field > label,
.rl-form .rl-field-group > label,
.rl-form .permission-selector-controls > label {
  padding: 15px 10px 7px;
  font-size: 15px;
  font-weight: 500;
}
.rl-form .rl-field > .sub-label,
.rl-form .rl-field-group > .sub-label,
.rl-form .permission-selector-controls > .sub-label {
  padding: 7px 10px 10px;
  font-size: 13px;
  color: rgba(var(--general-text-color-rgb), 0.4);
}
.rl-form .rl-field.bottom-space,
.rl-form .rl-field-group.bottom-space {
  margin-bottom: 15px;
}
.rl-form .rl-field.top-space,
.rl-form .rl-field-group.top-space {
  margin-top: 15px;
}
.rl-form .rl-input {
  position: relative;
  display: flex;
  border-top: 1px solid var(--general-border-color);
  border-bottom: 1px solid var(--general-border-color);
}
.rl-form .rl-input.disabled {
  opacity: 0.5;
}
.rl-form .rl-input > rl-icon,
.rl-form .rl-input .prefix > rl-icon {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  display: inline-block;
  height: 50px;
  min-width: 40px;
  line-height: 50px;
  font-size: 17px;
  text-align: center;
}
.rl-form .rl-input rl-icon + input,
.rl-form .rl-input rl-icon + select,
.rl-form .rl-input rl-icon + label {
  padding-left: 40px;
}
.rl-form .rl-input.time,
.rl-form .rl-input.date-time {
  display: flex;
}
.rl-form .rl-input.time .prefix,
.rl-form .rl-input.date-time .prefix {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 50px;
  padding-right: 10px;
  color: var(--general-text-color);
  background: var(--general-background-color);
  background-clip: padding-box;
}
.rl-form .rl-input.time .prefix::-webkit-input-placeholder,
.rl-form .rl-input.date-time .prefix::-webkit-input-placeholder {
  color: var(--general-text-color);
  opacity: 0.6;
}
.rl-form .rl-input.time input,
.rl-form .rl-input.date-time input {
  flex: 1;
  padding: 0 10px 0 0;
}
.rl-form .rl-input.time input.ng-invalid:not(.ng-untouched),
.rl-form .rl-input.date-time input.ng-invalid:not(.ng-untouched) {
  border: 1px solid var(--alert-color);
}
.rl-form .rl-input.time rl-icon + label,
.rl-form .rl-input.date-time rl-icon + label {
  padding: 0;
}
.rl-form .rl-input.time rl-icon,
.rl-form .rl-input.date-time rl-icon {
  position: static;
}
.rl-form .rl-input.number {
  display: flex;
}
.rl-form .rl-input.number input {
  width: 70px;
  padding-right: 0;
}
@media only screen and (min-width: 1025px) {
  .rl-form .rl-input.number input {
    width: 80px;
  }
}
.rl-form .rl-input.number .postfix {
  flex: 1;
  display: flex;
  align-items: center;
  color: var(----general-text-color);
  background: var(--general-background-color);
}
.rl-form .rl-input.toggle {
  justify-content: space-between;
  align-items: center;
}
.rl-form .rl-input.toggle label {
  flex: 1;
}
.rl-form .rl-input.toggle rl-icon + label {
  padding-left: 30px;
}
.rl-form input,
.rl-form select,
.rl-form .rl-input.toggle {
  width: 100%;
  height: 50px;
  padding: 0 10px;
  box-sizing: border-box;
  line-height: 17px;
  font-size: 15px;
  -webkit-appearance: none;
  color: var(--general-text-color);
  background: var(--general-background-color);
  background-clip: padding-box;
}
.rl-form input::-webkit-input-placeholder,
.rl-form select::-webkit-input-placeholder,
.rl-form .rl-input.toggle::-webkit-input-placeholder {
  color: var(--general-text-color);
  opacity: 0.6;
}
.rl-form textarea {
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
  color: var(--general-text-color);
  background: var(--general-background-color);
  background-clip: padding-box;
}
.rl-form textarea::-webkit-input-placeholder {
  color: var(--general-text-color);
  opacity: 0.6;
}
.rl-form input,
.rl-form select {
  border-radius: 0;
  border: none;
}
.rl-form permission-selector #permissions,
.rl-form multiple-permission-selector #permissions,
.rl-form multiple-permission-selector-wrapper #permissions {
  display: flex;
  padding: 8px 10px 0 0;
  font-size: 15px;
  border-top: 1px solid rgba(var(--general-text-color-rgb), 0.1);
  border-bottom: 1px solid rgba(var(--general-text-color-rgb), 0.1);
  color: var(--general-text-color);
  background: var(--general-background-color);
  background-clip: padding-box;
}
.rl-form permission-selector #permissions::-webkit-input-placeholder,
.rl-form multiple-permission-selector #permissions::-webkit-input-placeholder,
.rl-form multiple-permission-selector-wrapper #permissions::-webkit-input-placeholder {
  color: var(--general-text-color);
  opacity: 0.6;
}
.rl-form permission-selector .click-area:before,
.rl-form multiple-permission-selector .click-area:before,
.rl-form multiple-permission-selector-wrapper .click-area:before {
  content: "\f007";
  display: inline-block;
  height: 30px;
  min-width: 40px;
  font-family: "Font Awesome 6\ Pro";
  font-size: 17px;
  line-height: 30px;
  font-weight: 300;
  text-align: center;
}
.rl-form multiple-permission-selector #permissions,
.rl-form multiple-permission-selector-wrapper #permissions {
  border-top: unset;
  border-bottom: unset;
}
.rl-form .arrow:after {
  content: '';
  position: absolute;
  right: 12px;
  top: 50%;
  display: block;
  width: 10px;
  height: 10px;
  margin-top: -5px;
  transform: rotate(-45deg);
  border: solid var(--general-text-color);
  border-width: 0 2px 2px 0;
  opacity: 0.5;
}
.rl-form multiple-permission-selector-wrapper .permissions-set {
  border-bottom: 1px solid rgba(var(--general-text-color-rgb), 0.1);
}
.rl-new-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: var(--spacing);
  padding: var(--spacing);
}
.rl-new-form .form-item {
  display: flex;
  flex-direction: column;
}
.rl-new-form .form-item > label {
  font-size: 0.9rem;
  font-weight: 600;
  padding: 0 var(--m-spacing) var(--s-spacing);
}
.rl-new-form .form-item > label.required::after {
  content: '*';
  color: var(--highlight-color);
}
.rl-new-form .form-item:has(.required-field-empty) .text-field {
  border: 1px solid var(--alert-color);
}
.rl-new-form .form-item:has(.required-field-empty) rl-phone-input .select-area {
  border: 1px solid var(--alert-color);
}
.rl-new-form .form-item:has(.required-field-empty) rl-phone-input rl-text-input input {
  border: 1px solid var(--alert-color);
}
.rl-new-form .form-item:has(.required-field-empty) autocomplete input {
  border: 1px solid var(--alert-color);
}
.rl-new-form .form-item:has(.required-field-empty) rl-select .select-area {
  border: 1px solid var(--alert-color);
}
.rl-new-form .form-item:has(.error-message) .text-field {
  border: 1px solid var(--warning-color);
}
.rl-new-form .form-item:has(.error-message) rl-phone-input .select-area {
  border: 1px solid var(--warning-color);
}
.rl-new-form .form-item:has(.error-message) rl-phone-input rl-text-input input {
  border: 1px solid var(--warning-color);
}
.rl-new-form .form-item:has(.error-message) autocomplete input {
  border: 1px solid var(--warning-color);
}
.rl-new-form .form-item:has(.error-message) rl-select .select-area {
  border: 1px solid var(--warning-color);
}
.rl-new-form .text-field,
.rl-new-form .number-field {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  display: inline-flex;
  position: relative;
  flex: 1;
  padding: 0 var(--m-spacing);
  max-height: 45px;
  line-height: 45px;
  font-size: 1rem;
  color: var(--general-text-color);
  background-color: var(--background-highlight-color);
  border-radius: var(--general-border-radius);
  border: 1px solid var(--background-highlight-color);
}
.rl-new-form .text-field:focus-within,
.rl-new-form .number-field:focus-within {
  border: 1px solid var(--highlight-color);
}
.rl-new-form .text-field input,
.rl-new-form .number-field input {
  background-color: inherit;
  border: none;
  color: inherit;
  flex-basis: 100%;
  line-height: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  display: inline-flex;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  cursor: inherit;
}
.rl-new-form .text-field.disabled,
.rl-new-form .number-field.disabled {
  background-color: var(--locked-field-color);
  color: var(--general-text-inactive-color);
  border: none;
  cursor: not-allowed;
}
.rl-new-form .text-field.disabled input,
.rl-new-form .number-field.disabled input {
  background: unset;
  color: unset;
  opacity: 1;
}
.rl-new-form rl-select .select-area.opened {
  border: 1px solid var(--highlight-color) !important;
}
.rl-new-form rl-phone-input rl-text-input input:focus {
  border: 1px solid var(--highlight-color) !important;
}
.rl-new-form .select-field {
  position: relative;
}
.rl-new-form .select-field rl-select[disabled] {
  background: unset;
}
.rl-new-form .select-field rl-select[disabled] .select-area {
  color: var(--general-text-inactive-color);
  background-color: var(--locked-field-color);
  cursor: not-allowed;
  border: none;
}
.rl-new-form .select-field rl-select[disabled] .select-area span {
  opacity: 1;
}
.rl-new-form .field-postfix {
  color: var(--general-text-inactive-color);
}
.rl-new-form .toggle-field {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  display: inline-flex;
  position: relative;
  flex: 1;
  padding: 0 var(--m-spacing);
  max-height: 45px;
  line-height: 45px;
  font-size: 1rem;
  color: var(--general-text-color);
  background-color: var(--background-highlight-color);
  border-radius: var(--general-border-radius);
  border: 1px solid var(--background-highlight-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.rl-new-form .toggle-field:focus-within {
  border: 1px solid var(--highlight-color);
}
.rl-new-form .toggle-field input {
  background-color: inherit;
  border: none;
  color: inherit;
  flex-basis: 100%;
  line-height: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  display: inline-flex;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  cursor: inherit;
}
.rl-new-form .toggle-field.disabled {
  background-color: var(--locked-field-color);
  color: var(--general-text-inactive-color);
  border: none;
  cursor: not-allowed;
}
.rl-new-form .toggle-field.disabled input {
  background: unset;
  color: unset;
  opacity: 1;
}
.rl-new-form .toggle-field:focus-within {
  border: 1px solid transparent;
}
.rl-new-form .toggle-field rl-toggle {
  display: flex;
  align-items: center;
  height: 45px;
}
.rl-new-form .toggle-field rl-toggle label {
  padding: 0;
}
.rl-new-form .toggle-field rl-toggle input[type=checkbox]:checked + label {
  background-color: var(--highlight-color);
}
.rl-new-form .toggle-field rl-toggle input[type=checkbox]:disabled + label {
  cursor: not-allowed;
  background-color: var(--general-text-inactive-color);
}
.rl-new-form .toggle-field label {
  padding: 0;
  display: block;
  font-weight: 400;
  font-size: 1rem;
  text-wrap: wrap;
  word-break: keep-all;
  line-height: 1.1rem;
}
.rl-new-form .search-wrapper input:disabled {
  background: inherit;
  cursor: not-allowed;
  border: none;
  color: var(--general-text-inactive-color);
}
.rl-new-form .search-wrapper rl-icon {
  display: none;
}
.rl-new-form .rich-textarea-field rl-rich-textarea {
  border: none;
  position: relative;
}
.rl-new-form .rich-textarea-field rl-rich-textarea .fr-box.fr-basic .fr-wrapper,
.rl-new-form .rich-textarea-field rl-rich-textarea .fr-toolbar.fr-top {
  background-color: var(--background-highlight-color);
  border: 1px solid var(--background-highlight-color);
}
.rl-new-form .rich-textarea-field rl-rich-textarea .fr-box.fr-basic .fr-wrapper {
  border-top: none;
  border-radius: 0 0 var(--general-border-radius) var(--general-border-radius);
}
.rl-new-form .rich-textarea-field rl-rich-textarea .fr-toolbar.fr-top {
  border-bottom: none;
  border-radius: var(--general-border-radius) var(--general-border-radius) 0 0 !important;
  box-shadow: 0px -5px 2px 3px var(--general-background-color);
}
.rl-new-form .rich-textarea-field rl-rich-textarea:focus-within .fr-box.fr-basic .fr-wrapper,
.rl-new-form .rich-textarea-field rl-rich-textarea:focus-within .fr-toolbar.fr-top {
  border-color: var(--highlight-color);
}
.rl-new-form .rich-textarea-field:has(.fr-disabled.fr-toolbar) {
  cursor: not-allowed;
}
.rl-new-form .rich-textarea-field:has(.fr-disabled.fr-toolbar) .field-postfix {
  display: block;
}
.rl-new-form .rich-textarea-field .field-postfix {
  display: none;
  position: absolute;
  bottom: var(--m-spacing);
  right: var(--m-spacing);
  z-index: 1;
}
.rl-new-form .rich-textarea-field:has(.required-field-empty) .fr-box.fr-basic .fr-wrapper,
.rl-new-form .rich-textarea-field:has(.required-field-empty) .fr-toolbar.fr-top {
  border-color: var(--alert-color);
}
html[dir="rtl"] .rl-new-form rl-phone-input rl-select .select-area {
  padding: 0 10px 0 30px;
}
html[dir="rtl"] .rl-new-form .rich-textarea-field .field-postfix {
  right: unset;
  left: var(--m-spacing);
}
.rl-animation-fade {
  transition: 0.3s linear opacity;
}
.rl-animation-fade.ng-enter {
  opacity: 0;
}
.rl-animation-fade.ng-enter-active {
  opacity: 1;
}
.rl-animation-fade.ng-leave {
  opacity: 1;
}
.rl-animation-fade.ng-leave.ng-leave-active {
  opacity: 0;
}
.rl-animation-fade.ng-hide {
  opacity: 0;
}
.rl-animation-height {
  transition: 0.3s ease-in-out max-height, 0.3s ease-in-out opacity;
}
.rl-animation-height.ng-enter {
  overflow: hidden;
  max-height: 0;
  opacity: 0;
}
.rl-animation-height.ng-enter-active {
  overflow: hidden;
  max-height: 500px;
  opacity: 1;
}
.rl-animation-height.ng-leave {
  overflow: hidden;
  max-height: 500px;
  opacity: 1;
}
.rl-animation-height.ng-leave.ng-leave-active {
  overflow: hidden;
  max-height: 0;
  opacity: 0;
}
.rl-animation-height.ng-hide {
  overflow: hidden;
  max-height: 0;
  opacity: 0;
}
.rl-quick-filter .open-filter,
.rl-quick-filter.open-filter,
.rl-quick-filter .toggle-btn,
.rl-quick-filter .sort-btn {
  line-height: 36px;
  padding: 0 var(--m-spacing);
  font-size: 0.875rem;
  border-radius: var(--general-border-radius);
  background-color: rgba(var(--general-text-color-rgb), 0.06);
  cursor: pointer;
  backdrop-filter: blur(12px);
}
.rl-quick-filter .open-filter:hover,
.rl-quick-filter.open-filter:hover,
.rl-quick-filter .toggle-btn:hover,
.rl-quick-filter .sort-btn:hover {
  background-color: rgba(var(--general-text-color-rgb), 0.1);
}
.rl-quick-filter .open-filter rl-icon,
.rl-quick-filter.open-filter rl-icon,
.rl-quick-filter .toggle-btn rl-icon,
.rl-quick-filter .sort-btn rl-icon {
  font-size: 0.7rem;
  color: var(--general-text-inactive-color);
}
.rl-quick-filter .open-filter.active,
.rl-quick-filter.open-filter.active,
.rl-quick-filter .toggle-btn.active,
.rl-quick-filter .sort-btn.active,
.rl-quick-filter .open-filter.selected,
.rl-quick-filter.open-filter.selected,
.rl-quick-filter .toggle-btn.selected,
.rl-quick-filter .sort-btn.selected {
  background-color: var(--highlight-color);
  color: var(--text-on-highlight-color);
  border: 1px solid var(--highlight-color);
  font-weight: 600;
  max-height: 36px;
}
.rl-quick-filter .open-filter.active:hover,
.rl-quick-filter.open-filter.active:hover,
.rl-quick-filter .toggle-btn.active:hover,
.rl-quick-filter .sort-btn.active:hover,
.rl-quick-filter .open-filter.selected:hover,
.rl-quick-filter.open-filter.selected:hover,
.rl-quick-filter .toggle-btn.selected:hover,
.rl-quick-filter .sort-btn.selected:hover {
  background-color: color-mix(in srgb, var(--highlight-color) 90%, var(--text-on-highlight-color) 10%);
}
.rl-quick-filter .open-filter.active rl-icon,
.rl-quick-filter.open-filter.active rl-icon,
.rl-quick-filter .toggle-btn.active rl-icon,
.rl-quick-filter .sort-btn.active rl-icon,
.rl-quick-filter .open-filter.selected rl-icon,
.rl-quick-filter.open-filter.selected rl-icon,
.rl-quick-filter .toggle-btn.selected rl-icon,
.rl-quick-filter .sort-btn.selected rl-icon {
  color: var(--text-on-highlight-color);
}
.rl-large-headline {
  font-size: 28px;
  font-weight: 600;
  color: var(--general-text-color);
}
.rl-headline {
  font-size: 20px;
  font-weight: 600;
  color: var(--general-text-color);
}
.rl-headline-placeholder {
  height: 20px;
  border-radius: 4px;
  display: inline-block;
}
.rl-subheadline {
  font-size: 13px;
  color: var(--general-text-color);
  opacity: 0.4;
}
.rl-regular {
  font-size: 15px;
  font-weight: 400;
  color: var(--general-text-color);
}
.rl-small-text {
  font-size: 13px;
  font-weight: 400;
  color: var(--general-text-color);
}
.rl-upper {
  text-transform: uppercase;
}
.rl-bold {
  font-weight: 600;
}
.rl-link {
  color: var(--highlight-color);
  font-weight: 600;
}
.rl-label {
  font-size: 10px;
  font-weight: 600;
  color: var(--general-text-color);
}
.rl-label-inactive {
  font-size: 10px;
  font-weight: 400;
  color: var(--general-text-color);
  opacity: 0.4;
}
.rl-tabs-round {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 2px;
  width: 100%;
  height: 35px;
  border-radius: 17.5px;
  background: rgba(var(--general-text-color-rgb), 0.15);
  text-align: center;
  font-size: 13px;
  line-height: 16px;
}
.rl-tabs-round .rl-tab {
  flex: 1;
  height: 31px;
  line-height: 31px;
  border-radius: 17.5px;
  transition: all 0.2s ease-in;
}
.rl-tabs-round .rl-tab.active {
  font-weight: 700;
  background: var(--general-background-color);
}
.rl-tabs {
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid var(--general-border-color);
}
.rl-tabs.rl-tabs-full-width .rl-tab {
  flex: 1;
}
.rl-tabs .rl-tab {
  position: relative;
  top: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  height: 40px;
  box-sizing: border-box;
  border-bottom: 3px solid transparent;
  color: rgba(var(--general-text-color-rgb), 0.4);
  font-size: 0.875rem;
  cursor: pointer;
}
.rl-tabs .rl-tab.active {
  font-weight: 700;
  color: var(--highlight-color);
  border-bottom: 3px solid var(--highlight-color);
}
.rl-tabs .rl-tab rl-badge {
  margin-left: 8px;
}
.spinner {
  display: inline-block;
  position: relative;
  text-align: center;
  vertical-align: middle;
  overflow: hidden;
  text-indent: -9999px;
  width: 24px;
  height: 24px;
  border: solid 2px var(--general-text-color);
  border-bottom-color: transparent !important;
  border-right-color: transparent !important;
  border-radius: 50%;
  animation: spinner 400ms linear infinite;
}
.spinner.ng-animate {
  animation: none 0s;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.placeholder-shimmer {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: rgba(var(--general-text-color-rgb), 0.15);
  background: linear-gradient(to right, rgba(var(--general-text-color-rgb), 0.15) 4%, rgba(var(--general-text-color-rgb), 0.25) 25%, rgba(var(--general-text-color-rgb), 0.15) 36%);
  background-size: 80vw 100%;
}
.placeholder-shimmer.text-shimmer {
  width: 200px;
  height: 1.3rem;
  border-radius: var(--general-border-radius);
}
.placeholder-shimmer.text-shimmer.short-shimmer {
  width: 50px;
}
.placeholder-shimmer.text-shimmer.long-shimmer {
  width: 300px;
}
.placeholder-shimmer.circle-shimmer {
  border-radius: 50%;
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -80vw 0;
  }
  100% {
    background-position: 80vw 0;
  }
}
