rl-search-result-item {
    display: flex;
    gap: var(--m-spacing);

    .image {
        position: relative;
        width: 50px;
        height: 50px;
        border-radius: var(--general-border-radius);

        @media @desktop-up {
            width: 40px;
            height: 40px;
        }

        file-preview {
            width: 100%;
            height: 100%;
            border-radius: var(--general-border-radius);
            overflow: hidden;

            .background:not(.image):after {
                border-radius: var(--general-border-radius);
            }

            .background.no-preview:after {
                display: none;
            }

            .file-icon {
                font-size: 1.5rem;

                &[icon="play"] {
                    width: 2rem;
                    height: 2rem;
                    font-size: 1.3rem;

                    & span {
                        margin-left: 3px;
                    }
                }

                @media @desktop-up {
                    font-size: 1.3rem;
                    
                    &[icon="play"] {
                        width: 1.5rem;
                        height: 1.5rem;
                        font-size: 1rem;
                    }
                }
            }
        }

        > rl-icon {
            color: var(--general-text-inactive-color);
        }

        &:not(.has-media) {
            background-color: var(--general-border-color);

            > rl-icon {
                position: absolute;
                top: 50%;
                right: 50%;
                transform: translate(50%, -50%);

                font-size: 1.5rem;

                @media @desktop-up {
                    font-size: 1.25rem;
                }
            }
        }
    }

    .content-wrapper {
        display: flex;
        flex-direction: column;
        gap: 3px;
        flex: 1;
        overflow: hidden;
        margin-bottom: auto;

        .item-title {
            display: flex;
            overflow: hidden;
            margin: 0;

            font-size: 1rem;
            font-weight: 600;

            bdi:last-of-type {
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;
                width: 100%;
                white-space: nowrap;
            }
        }

        .meta {
            display: flex;
            white-space: nowrap;

            .name, .path {
                overflow: hidden;
                text-overflow: ellipsis;
            }

            span {
                font-size: 0.875rem;

                &:not(:first-child)::before {
                    content: '·';
                    font-weight: bold;
                    padding: 0 3px;
                }
            }
        }

        .content {
            margin: 0;
            font-size: 0.875rem;

            .teaser {
                display: none;
            }

            @media @mobile-max {
                .teaser.short {
                    display: inline-block;
                }
            }
            @media @tablet-up {
                .teaser:not(.short) {
                    display: inline-block;
                }
            }

            em {
                padding: 0 2px;
                background-color: rgba(var(--highlight-color-rgb), 0.15);
                border-radius: 4px;
                font-weight: 700;
                font-style: normal;
            }
        }

        &.catalog-category {
            margin-bottom: unset;
            justify-content: center;

            .item-title {
                align-items: flex-start;
                overflow: hidden;
                margin: 0;
                gap: 5px;
                font-weight: 600;
            }
        }
    }

    .breadcrumbs-list {
        margin: 0;
        padding: 0;
        display: flex;
        gap: 5px;
        list-style: none;
        line-height: 130%;

        li {
            color: var(--general-text-inactive-color);
            font-size: 0.85rem;
        }

        rl-icon {
            width: 0.8rem;
            font-size: 0.8rem;
        }
    }
}